import { Component, IComponentOptions } from '../component';
import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import { FormattedDate } from '../date-util';
import * as dom from '../dom';
import * as utility from '../utility';

// tslint:disable-next-line
export interface IDateComponentOptions extends IComponentOptions { }

export class DateComponent extends Component {

	private _mutationObserver = new MutationObserver((events) => {
		const isDateUpdated = !!events.find((event) => event.attributeName === 'data-datetime');
		if (!isDateUpdated) {
			return;
		}

		if (this.datetime) {
			this._updateDate(this.datetime);
		}

		if (this.dateFormat.length > 0) {
			this._buildDateComponent();
		}
	});

	constructor(element: HTMLElement, options?: IDateComponentOptions) {
		super(element, options);

		if (this.dateFormat.length > 0 || this.element.children.length === 0) {
			this._buildDateComponent();
		}

		if (this.datetime) {
			this._updateDate(this.datetime);
		}

		this._mutationObserver.observe(this.element, { attributes: true });
	}

	get datetime(): string | null {
		return this.element.getAttribute('data-datetime');
	}

	set datetime(date: string) {
		this.element.setAttribute('data-datetime', date);
		this._updateDate(date);
	}

	get dateFormat(): string {
		return (this.element.getAttribute('data-dateformat') || '').toUpperCase();
	}

	set dateFormat(format: string) {
		this.element.setAttribute('data-dateformat', format);
		this._buildDateComponent();
	}

	public destroy() {
		this._mutationObserver.disconnect();
		if (this.dateFormat.length > 0 && this.element) {
			this.element.innerHTML = '';
		}
	}

	private _updateDate(datetime: string) {
		const isoString = new Date(datetime).toISOString();
		const params = isoString.split('-', 3).map((numberString) => parseInt(numberString, 10));
		const date = new Date(params[0], params[1] - 1, params[2]);

		const formattedDate = new FormattedDate(date);
		const dayElement = this.element.querySelector('.iris-date__day') as HTMLElement;
		const monthElement = this.element.querySelector('.iris-date__month') as HTMLElement;
		const yearElement = this.element.querySelector('.iris-date__year') as HTMLElement;

		this.element.setAttribute('aria-label', formattedDate.fullDate);

		if (dayElement) {
			dayElement.setAttribute('aria-hidden', 'true');
			dayElement.innerText = formattedDate.dayOfMonth;
		}
		if (monthElement) {
			monthElement.setAttribute('aria-hidden', 'true');
			monthElement.innerText = formattedDate.abbreviatedMonth;
		}
		if (yearElement) {
			yearElement.setAttribute('aria-hidden', 'true');
			yearElement.innerText = formattedDate.year;
		}
	}

	private _buildDateComponent() {
		if (this.dateFormat) {
			this.element.innerHTML = '';
		}

		const elements = utility.toArray(dom.createElementsFromHTML(`
			<span class="iris-date__day"></span>
			<span class="iris-date__month"></span>
			<span class="iris-date__year"></span>`));

		const formatElements: { [key: string]: Element } = {
			D: elements[0],
			M: elements[1],
			Y: elements[2],
		};

		const orderedElements: (Element | string)[] = [];
		const format = this.dateFormat.split('') || [];

		if (format.length > 0) {
			format.forEach((formatItem, index) => {
				const results: (Element | string)[] = [];
				const formatElement = formatElements[formatItem];

				if (!formatElement) {
					return;
				}

				results.push(formatElement);

				if (this.element.classList.contains('iris-date--linear') && index < format.length - 1) {
					results.push(' ');
				}

				orderedElements.push(...results);
			});
		} else if (this.element.classList.contains('iris-date--linear')) {
			orderedElements.push(elements[0], ' ', elements[1], ' ', elements[2]);
		} else if (this.element.classList.contains('iris-date--stacked-full')) {
			orderedElements.push(elements[1], elements[0], elements[2]);
		} else if (this.element.classList.contains('iris-date--stacked-month-year')) {
			orderedElements.push(elements[1], elements[2]);
		}

		this.element.append(...orderedElements);
	}

	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '.iris-date',
		componentName: 'DateComponent'
	};

	private static _defaultComponentOptions: IDateComponentOptions = {
		idPrefix: 'iris_date',
	};

	public static factory = new GenericComponentFactory<DateComponent, IDateComponentOptions>(DateComponent, DateComponent._factoryOptions, DateComponent._defaultComponentOptions);
}

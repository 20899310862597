import { generateUniqueId } from '../utility';

export type Politeness = 'assertive' | 'polite' | 'off';

let _statusRegion: LiveRegion = null;

export class LiveRegion {
	private _element: HTMLElement;

	constructor() {
		this._element = document.createElement('DIV');
		this._element.id = generateUniqueId('iris_live_region');
		this._element.classList.add('iris-live-region');
		this._element.setAttribute('aria-atomic', 'true');
		this._element.setAttribute('role', 'status');
		this.politeness = 'assertive';

		document.body.appendChild(this._element);
	}

	get id(): string {
		return this._element.id;
	}

	get element(): HTMLElement {
		return this._element;
	}

	get politeness(): Politeness {
		return this._element.getAttribute('aria-live') as Politeness;
	}

	set politeness(politeness: Politeness) {
		this._element.setAttribute('aria-live', politeness);
	}

	public broadcast(message: string): void {
		this._element.innerText = message;

		// tslint:disable-next-line no-console
		console.log('Broadcast: ', message);

		setTimeout(() => {
			this._element.innerHTML = '';
		}, 500);
	}

	static get globalStatusRegion(): LiveRegion {
		const id = 'iris__global_status_region';
		if (!_statusRegion) {
			_statusRegion = new LiveRegion();

			if (!document.getElementById(id)) {
				_statusRegion.element.id = id;
			}
		}

		return _statusRegion;
	}
}

import { Component, IComponentOptions } from '../component';
import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import * as dom from '../dom';
import { FocusManager } from '../focus-manager';
import * as utility from '../utility';
import { CompassStep } from './compass-step';

export interface ICompassComponentOptions extends IComponentOptions {
	stepChangeHandlers?: ICompassStepChangeHandlers;
}

export interface ICompassStepChangeHandler {
	after?: EventListener;
	before?: EventListener;
	done?: EventListener;
	buttons?: { [key: string]: string };
}

export interface ICompassStepChangeHandlers {
	[key: string]: ICompassStepChangeHandler;
}

export type CompassStepChangeAction = 'after' | 'before' | 'done';
export type CompassAsyncHandlerCallback = (success: () => void, event: CustomEvent, element: HTMLElement) => void;


const EVENT_ACTION_MAP = {
	'iris.compass.stepchange.after': { action: 'after', stepKey: 'activeStep' },
	'iris.compass.stepchange.before': { action: 'before', stepKey: 'nextStep' },
	'iris.compass.done': { action: 'done', stepKey: 'step' }
} as { [key: string]: { action: CompassStepChangeAction, stepKey: string } };


export class CompassComponentFactory extends GenericComponentFactory<CompassComponent, ICompassComponentOptions> {
	public createAsyncHandler(cb: CompassAsyncHandlerCallback): EventListener {
		let asyncCallSuccess = false;
		let component: CompassComponent;
		let step: CompassStep;

		const success = function() {
			asyncCallSuccess = true;
			component.activeStep = step;
		};

		return function(event: CustomEvent) {
			step = event.detail.nextStep;
			component = event.detail.component;

			if (!asyncCallSuccess) {
				cb(success, event, this);
				return false;
			}

			asyncCallSuccess = false;
			return true;
		};
	}
}

export class CompassComponent extends Component {

	private _cancelStepChange = false;
	private _focusManager: FocusManager;
	private _mutationObserver: MutationObserver;
	private _stepChangeHandlers: ICompassStepChangeHandlers;
	private _steps: CompassStep[] = [];
	private _triggerNextElements: HTMLElement[] = [];
	private _triggerPreviousElements: HTMLElement[] = [];
	private _triggerDoneElements: HTMLElement[] = [];
	private _titleElements: HTMLElement[] = [];

	constructor(element: HTMLElement, options?: ICompassComponentOptions) {
		super(element, options);
		if (options.stepChangeHandlers) {
			this.stepChangeHandlers = options.stepChangeHandlers;
		}

		this._focusManager = new FocusManager(this.element, [
			[
				'input:not([type=hidden])',
				'select',
				'.iris-dropdown__capture-input',
				'.iris-compass-step[data-compass-active="true"] .iris-compass-step__body button',
			],
			[
				'.iris-compass-step[data-compass-active="true"]'
			],
		]);

		this.element.addEventListener('iris.compass.stepchange.after', this._stepChangeHandler);
		this.element.addEventListener('iris.compass.stepchange.before', this._stepChangeHandler);
		this.element.addEventListener('iris.compass.done', this._stepChangeHandler);

		this._updateTriggerElements();
		this._updateTitleElements();
		this._updateComponentState();

		this.reset();

		this._mutationObserver = new MutationObserver(this._mutationObserverHandler);
		this._mutationObserver.observe(this.element, { attributes: true, childList: true });
	}

	public get activeStep(): CompassStep {
		return this.steps.find((step) => step.active);
	}

	public set activeStep(step: CompassStep) {
		const oldStep = this.activeStep;

		if (!(step || oldStep) || oldStep === step) {
			return;
		}

		this._dispatchEvent('iris.compass.stepchange.before', {
			activeStep: oldStep,
			component: this,
			nextStep: step
		});


		if (this._cancelStepChange) {
			this._cancelStepChange = false;
			return;
		}

		this.steps.forEach((_step) => _step.active = _step === step);

		this._updateUI();
		this._focusManager.setFocus();

		const detail = {
			activeStep: step,
			component: this,
			previousStep: oldStep
		};

		this._dispatchEvent('iris.compass.stepchange.after', detail);
	}

	public get autowireSteps() {
		return this.element.hasAttribute('data-compass-autowire-steps') || false;
	}

	public get stepChangeHandlers() {
		return this._stepChangeHandlers || {};
	}

	public set stepChangeHandlers(stepChangeHandlers: ICompassStepChangeHandlers) {
		this._stepChangeHandlers = stepChangeHandlers;
	}

	public get steps(): CompassStep[] {
		return this._steps;
	}

	public get titleElements() {
		return this._titleElements;
	}

	public get triggerDoneElements() {
		return this._triggerDoneElements;
	}

	public get triggerNextElements() {
		return this._triggerNextElements;
	}

	public get triggerPreviousElements() {
		return this._triggerPreviousElements;
	}

	public activateDone() {
		const doneStep = this.activeStep;
		this.activeStep = null;

		this._dispatchEvent('iris.compass.done', { component: this, step: doneStep });
	}

	public activateNextStep(): CompassStep {
		if (!this.activeStep) {
			return;
		}

		const step = this.stepById(this.activeStep.nextStepId);

		if (step) {
			step.element.classList.add('iris-compass-step--next');
			this.activeStep = step;
		}

		return step;
	}

	public activatePreviousStep(): CompassStep {
		if (!this.activeStep) {
			return;
		}

		const step = this.stepById(this.activeStep.previousStepId);

		if (step) {
			step.element.classList.add('iris-compass-step--previous');
			this.activeStep = step;
		}

		return step;
	}

	public cancelStepChange(): void {
		this._cancelStepChange = true;
	}

	public destroy(): void {

		this.element.removeEventListener('iris.compass.stepchange.after', this._stepChangeHandler);
		this.element.removeEventListener('iris.compass.stepchange.before', this._stepChangeHandler);
		this.element.removeEventListener('iris.compass.done', this._stepChangeHandler);

		this._removeTriggerElementEvents();
		return;
	}

	public disableNext(bool: boolean) {
		this.triggerNextElements.forEach((element) => this._disableElement(element, bool));
	}

	public disablePrevious(bool: boolean) {
		this.triggerPreviousElements.forEach((element) => this._disableElement(element, bool));
	}

	public disableDone(bool: boolean) {
		this.triggerDoneElements.forEach((element) => this._disableElement(element, bool));
	}

	public actionEventListener(id: string, action: CompassStepChangeAction): EventListener {
		// Determines if the handlers have an object with the id of the step.
		// If it does then use it's defined handler for that action
		// else if it isn't defined, use the default one if it's defined.
		// null handlers will be used to break from falling into default.

		const defaultActionHandlers = this.stepChangeHandlers.default as ICompassStepChangeHandler;
		const defaultActionHandler = defaultActionHandlers && defaultActionHandlers[action] as EventListener;
		const actionHandlers = (this.stepChangeHandlers[id] || defaultActionHandlers) as ICompassStepChangeHandler;

		if (!actionHandlers) {
			return null;
		}

		const actionHandler = typeof actionHandlers[action] === 'undefined' ? defaultActionHandler : actionHandlers[action];

		return actionHandler || null;
	}

	public reset(): void {
		this.activeStep = null;
		this.activeStep = this.steps.find((step) => step.isFirst) || this.steps[0];
	}

	public stepById(id: string): CompassStep {
		return this.steps.find((step) => step.id === id) || null;
	}

	private _addTriggerElementEvents(): void {
		this.triggerNextElements.forEach((element) => element.addEventListener('click', this._triggerNextClickHandler));
		this.triggerPreviousElements.forEach((element) => element.addEventListener('click', this._triggerPreviousClickHandler));
		this.triggerDoneElements.forEach((element) => element.addEventListener('click', this._triggerDoneClickHandler));
	}

	private _disableElement(element: HTMLElement, bool: boolean) {
		if (bool) {
			element.setAttribute('disabled', 'disabled');
		} else {
			element.removeAttribute('disabled');
		}

		element.setAttribute('aria-disabled', bool.toString());
	}

	private _removeTriggerElementEvents(): void {
		this.triggerNextElements.forEach((element) => element.removeEventListener('click', this._triggerNextClickHandler));
		this.triggerPreviousElements.forEach((element) => element.removeEventListener('click', this._triggerPreviousClickHandler));
		this.triggerDoneElements.forEach((element) => element.removeEventListener('click', this._triggerDoneClickHandler));
	}


	private _updateComponentState(): void {
		this._updateSteps();
	}

	private _updateTriggerElements(): void {
		this._removeTriggerElementEvents();

		this._triggerNextElements = utility.toArray(document.querySelectorAll(`#${this.element.id} [data-compass-action="next"], [data-compass="${this.element.id}"] [data-compass-action="next"]`));
		this._triggerPreviousElements = utility.toArray(document.querySelectorAll(`#${this.element.id} [data-compass-action="previous"], [data-compass="${this.element.id}"] [data-compass-action="previous"]`));
		this._triggerDoneElements = utility.toArray(document.querySelectorAll(`#${this.element.id} [data-compass-action="done"], [data-compass="${this.element.id}"] [data-compass-action="done"]`));

		this._addTriggerElementEvents();
	}

	private _updateTitleElements(): void {
		this._titleElements = utility.toArray(document.querySelectorAll(`#${this.element.id} [data-compass-title], [data-compass="${this.element.id}"] [data-compass-title]`));
	}

	private _updateSteps(): void {
		const stepElements = utility.toArray(this.element.querySelectorAll('.iris-compass-step'));

		const steps = stepElements.map((stepElement, index) => {
			if (this.autowireSteps && !stepElement.id) {
				stepElement.id = utility.generateUniqueId('iris_compass_step');
			}

			const step = this._steps.find((oldStep) => oldStep.id === stepElement.id) || new CompassStep(stepElement);

			return step;
		});

		if (this.autowireSteps) {
			steps.forEach((step, index) => {
				if (this.autowireSteps) {
					const nextStep = steps[index + 1];
					const previousStep = steps[index - 1];

					step.nextStepId = nextStep ? nextStep.element.id : null;
					step.previousStepId = previousStep ? previousStep.element.id : null;
				}
			});
		}

		this._steps = steps;
	}

	private _updateUI(): void {
		const activeStep = this.activeStep;
		const active = (element: HTMLElement, show: boolean) => {
			const isAlreadyActive = element.getAttribute('data-compass-active') === 'true';

			if (element.hasAttribute('data-compass-active') && show === isAlreadyActive) {
				return;
			}

			element.setAttribute('data-compass-active', show.toString());
			element.setAttribute('aria-hidden', (!show).toString());
			CompassStep.setupTransition(element, show);
		};

		const title = (activeStep && activeStep.title) || '';
		this.titleElements.forEach((element) => element.innerText = title);

		const triggerElements = this.triggerPreviousElements.concat(this.triggerNextElements, this.triggerDoneElements);

		triggerElements.forEach((element) => active(element,
			!!(activeStep && ((element.matches('[data-compass-action="next"]') && activeStep.nextStepId) ||
				(element.matches('[data-compass-action="previous"]') && activeStep.previousStepId) ||
				(element.matches('[data-compass-action="done"]') && !activeStep.nextStepId)))
		));

		if (!activeStep) {
			return;
		}

		const shownSelector = this.steps.map((step) => `[data-compass-step-show~=${step.id}]`).join(', ');
		const shownElements = utility.toArray(document.querySelectorAll(shownSelector)) as HTMLElement[];
		const hiddenSelector = this.steps.map((step) => `[data-compass-step-hide~=${step.id}]`).join(', ');
		const hiddenElements = utility.toArray(document.querySelectorAll(hiddenSelector)) as HTMLElement[];

		shownElements.forEach((element) => active(element, element.matches(`[data-compass-step-show~=${activeStep.id}]`)));
		hiddenElements.forEach((element) => active(element, !element.matches(`[data-compass-step-hide~=${activeStep.id}]`)));
	}

	private _stepChangeHandler = function(event: CustomEvent) {
		const { action, stepKey } = EVENT_ACTION_MAP[event.type];
		const component = event.detail.component as CompassComponent;
		const step = event.detail[stepKey] as CompassStep;

		if (!step) { return; }

		const handler = component.actionEventListener(step.id, action);

		if (!handler) {
			return;
		}

		const result = handler.bind(this)(event);

		if (action === 'before' && result === false) {
			component.cancelStepChange();
		}

	};

	private _mutationObserverHandler = (records: MutationRecord[]) => {
		this._updateComponentState();
	}

	private _triggerDoneClickHandler = (event: MouseEvent) => {
		this.activateDone();
	}

	private _triggerNextClickHandler = (event: MouseEvent) => {
		this.activateNextStep();
	}

	private _triggerPreviousClickHandler = (event: MouseEvent) => {
		this.activatePreviousStep();
	}

	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '.iris-compass',
		componentName: 'CompassComponent'
	};

	private static _defaultComponentOptions: ICompassComponentOptions = {
		idPrefix: 'iris_compass',
	};

	public static factory = new CompassComponentFactory(CompassComponent, CompassComponent._factoryOptions, CompassComponent._defaultComponentOptions);
}

import * as dom from '../dom';
import { RegionSettings } from '../regional';
import { generateUniqueId, toArray } from '../utility';
import { IContentHeaderOptions } from './content-header';
import { SyntheticButtonComponent } from './synthetic-button';

export type SortDirection = 'ascending' | 'descending';

export class ContentHeaderItem {
	private _active: boolean = false;
	private _describedbyElement: HTMLElement = null;
	private _element: HTMLElement;
	private _label: string;
	private _options: IContentHeaderOptions;
	private _sortDirection: SortDirection = 'descending';

	constructor(element: HTMLElement, options: IContentHeaderOptions) {
		this._element = element;
		this._label = element.innerText.trim().toLowerCase();
		this._options = options;

		// Make sure the element has an id before setting the header id.
		element.id = element.id || generateUniqueId('content_header');

		// Create a new element for the describedby text
		this._describedbyElement = document.createElement('div');
		this._describedbyElement.id = `${this._element.id}_desc`;
		this._describedbyElement.innerText = `Activate button to sort by ${this._label} ${this.oppositeSortDirection}`;
		this._describedbyElement.classList.add('sr-only');
		this._element.appendChild(this._describedbyElement);

		// Add the aria-label and aria-describedby attributes.
		this._element.setAttribute('aria-label', this._label);
		this._element.setAttribute('aria-describedby', `${this._element.id}_desc`);

		// Check whether there is already a class on the item, indicating it is sorted
		// by default and set the direction and add active if so.
		if (this._element.classList.contains('iris-content-header__item--ascending')) {
			this._sortDirection = 'ascending';
			this.active = true;
		} else if (this._element.classList.contains('iris-content-header__item--descending')) {
			this._sortDirection = 'descending';
			this.active = true;
		}

		this._element.addEventListener('click', this._clickHandler);
		SyntheticButtonComponent.factory.init(this._element);
	}

	public destroy() {
		this._resetComponent();
		this._element.removeEventListener('click', this._clickHandler);
		SyntheticButtonComponent.factory.destroy(this._element);
	}


	// Getters and Setters
	// =============================================================================
	get active() {
		return this._active;
	}

	set active(isActive: boolean) {
		if (this._active) {
			if (isActive) {
				// We were active, are still active, and need to REVERSE the directional classes.
				this._reverseDirectionalClasses();
			} else {
				// We were active, are now inactive, and need to REMOVE the directional classes.
				this._removeDirectionalClasses();
			}
		} else if (isActive) {
			// We were inactive, are now active, and need to ADD the directional classes.
			this._addDirectionalClasses();
		}

		// Wait until the end to set the internal active status so we can compare before and after first.
		this._active = isActive;
		this._updateAriaAttributes();
	}

	get direction() {
		return this._sortDirection;
	}

	get oppositeSortDirection() {
		if (!this.active || this._sortDirection === 'ascending') {
			return 'descending';
		}
		return 'ascending';
	}

	get element() {
		return this._element;
	}


	// Private Methods
	// =============================================================================
	private _resetComponent() {
		this._element.classList.remove('iris-content-header__item--descending', 'iris-content-header__item--ascending');
		this._element.removeAttribute('aria-label');
		this._element.removeAttribute('aria-describedby');
		this._element.removeChild(this._describedbyElement);
	}

	private _clickHandler = (event: Event) => {
		dom.dispatchEvent(this.element, '_contentHeaderItemSelected', {
			component: this,
		});

		event.cancelBubble = true;
	}

	private _addDirectionalClasses() {
		if (this._sortDirection === 'ascending') {
			this._element.classList.add('iris-content-header__item--ascending');
		} else {
			this._element.classList.add('iris-content-header__item--descending');
		}
	}

	private _removeDirectionalClasses() {
		this._element.classList.remove('iris-content-header__item--ascending');
		this._element.classList.remove('iris-content-header__item--descending');
	}

	private _reverseDirectionalClasses() {
		if (this._sortDirection === 'ascending') {
			this._sortDirection = 'descending';
			this._element.classList.remove('iris-content-header__item--ascending');
			this._element.classList.add('iris-content-header__item--descending');
			return;
		}

		this._sortDirection = 'ascending';
		this._element.classList.remove('iris-content-header__item--descending');
		this._element.classList.add('iris-content-header__item--ascending');
		return;
	}

	private _updateAriaAttributes() {
		if (this._active) {
			this._element.setAttribute('aria-label', RegionSettings.format(`contentheader.item.${this._sortDirection}label` as keyof RegionSettings, this._label));
		} else {
			this._element.setAttribute('aria-label', RegionSettings.format('contentheader.item.neutrallabel', this._label));
		}
		this._describedbyElement.innerText = RegionSettings.format(`contentheader.item.${this.oppositeSortDirection}directions` as keyof RegionSettings, this._label);
	}
}

import {Component, IComponentOptions} from '../component';
import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import * as dateUtility from '../date-util';
import * as dom from '../dom';
import * as utility from '../utility';
import {CalendarDayComponent, ICalendarDayEvent} from './calendar-day';

export interface ICalendarOptions extends IComponentOptions {
	startDateLimit: Date | string | null;
	endDateLimit: Date | string | null;
	calendarEvents: ICalendarDayEvent[];
	defaultAvatarIconClass?: string;
	selectedEventDayName: string;
	previousMonthClickEventName: string;
	nextMonthClickEventName: string;
	initDate?: null | string;
}

interface INavigationAmount {
	left: number;
	right: number;
	up: number;
	down: number;
	[key: string]: number;
}

interface IFullDayNames {
	sun: string;
	mon: string;
	tue: string;
	wed: string;
	thu: string;
	fri: string;
	sat: string;
	[key: string]: string;
}

export class CalendarComponent extends Component {
	private _calendarHeader: HTMLElement;
	private _calendarTitleElement: HTMLElement;
	private _currentMonth: number;
	private _currentYear: number;
	private _dayComponents: CalendarDayComponent[];
	private _events: ICalendarDayEvent[];
	private _nextMonthButtonElement: HTMLButtonElement;
	private _options: ICalendarOptions;
	private _prevMonthButtonElement: HTMLButtonElement;
	private _todaysDate: Date;
	private _initDate: Date;

	constructor(element: HTMLElement, options?: ICalendarOptions) {
		super(element, options);

		// Set private variables
		this._events = options.calendarEvents;
		this._options = options;
		this._todaysDate = new Date();
		this._initDate = options.initDate ? new Date(options.initDate) : this._todaysDate;
		this._currentMonth = this._initDate.getUTCMonth();
		this._currentYear = this._initDate.getUTCFullYear();

		// Generate the calendar markup
		this._generateCalendarHeader();
		this._generateMonthRowsAndDays();

		// Add event listeners
		this._addEventListeners();
	}

	public destroy() {
		this._dayComponents.forEach((calendarDay) => {
			calendarDay.destroy();
		});

		this._prevMonthButtonElement.removeEventListener('click', this._previousMonthClickHandler);
		this._nextMonthButtonElement.removeEventListener('click', this._nextMonthClickHandler);
		this.element.removeEventListener('_calendarDaySelected', this._calendarChildSelectedHandler);
	}

	// Element creator methods
	// =============================================================================
	private _generateCalendarHeader() {
		// build Header
		const calendarHeader = document.createElement('div');
		calendarHeader.classList.add('iris-calendar__header', 'flex', 'flex-items--center');

		const calendarTitleElement = this._generateTitle();
		const prevButtonElement = this._generatePrevMonth();
		const nextButtonElement = this._generateNextMonth();

		calendarHeader.appendChild(calendarTitleElement);
		calendarHeader.appendChild(prevButtonElement);
		calendarHeader.appendChild(nextButtonElement);

		this._calendarHeader = calendarHeader;
		this.element.prepend(calendarHeader);
	}

	private _generateTitle() {
		// calendar title
		const calendarTitle = document.createElement('h2');
		calendarTitle.classList.add('iris-calendar__header-title', 'mar--0');
		calendarTitle.append(`${this._nameOfMonth(this._currentMonth)} ${this._currentYear}`);

		this._calendarTitleElement = calendarTitle;

		return calendarTitle;
	}

	private _generatePrevMonth() {
		// previous button arrow
		const prevMonthButton = document.createElement('button');
		prevMonthButton.classList.add('iris-button', 'iris-button--ghost', 'iris-calendar__header-prev-month');
		prevMonthButton.setAttribute('aria-label', 'previous month');

		const prevMonthArrow = document.createElement('span');
		prevMonthArrow.classList.add('iris-button__icon', 'font-icon-angle-left');
		prevMonthButton.appendChild(prevMonthArrow);

		this._prevMonthButtonElement = prevMonthButton;

		return prevMonthButton;
	}

	private _generateNextMonth() {
		// next month button arrow
		const nextMonthButton = document.createElement('button');
		nextMonthButton.classList.add('iris-button', 'iris-button--ghost', 'iris-calendar__header-next-month');
		nextMonthButton.setAttribute('aria-label', 'next month');

		const nextMonthArrow = document.createElement('span');
		nextMonthArrow.classList.add('iris-button__icon', 'font-icon-angle-right');
		nextMonthButton.appendChild(nextMonthArrow);

		this._nextMonthButtonElement = nextMonthButton;

		return nextMonthButton;
	}

	private _generateMonthRowsAndDays() {
		this._dispatchEvent('iris.calendar.generate.start', { component: this });

		// Build DaysContainer
		const daysContainerElement = document.createElement('div');
		daysContainerElement.classList.add('iris-calendar__days');
		daysContainerElement.setAttribute('role', 'grid');

		const firstWeekday = this._firstDayOfMonth(this._currentMonth, this._currentYear);
		const numberOfDays = this._daysInMonth(this._currentMonth, this._currentYear);
		const weeks = this._numberOfWeeks(firstWeekday, numberOfDays);

		const daysOfTheWeekNames = this._generateDaysOfTheWeekNames();

		daysContainerElement.append(daysOfTheWeekNames);

		// build dayRowsElements
		const dayRowsElements = utility.range(weeks).map((rowIndex) => {
			const row = document.createElement('div');
			row.classList.add('iris-calendar__days-row');
			row.setAttribute('role', 'row');
			row.setAttribute('aria-rowindex', `${rowIndex}`);

			if (rowIndex === 0 && firstWeekday !== 0) {
				utility.range(firstWeekday).forEach((day) => {
					const spacer = document.createElement('div');
					spacer.classList.add('iris-calendar__days-spacer');
					spacer.setAttribute('role', 'gridcell');
					row.appendChild(spacer);
				});
			}

			daysContainerElement.append(row);
			return row;
		});

		// generate day component
		this._dayComponents = utility.range(1, numberOfDays + 1).map((day: number) => {
			const date = new Date(this._currentYear, this._currentMonth, day);

			// Search through the events object and look for an event that happens on this day
			const eventOnThisDay = this._events.find((event) => {
				if (event.eventDate) {
					return dateUtility.isSameDay(event.eventDate, date);
				}
			});

			const calendarLimitOptions = {
				endDateLimit: this._options.endDateLimit,
				startDateLimit: this._options.startDateLimit
			};

			const dayComponent = new CalendarDayComponent(date, this._todaysDate, this._mergeEventWithDefaults(eventOnThisDay), calendarLimitOptions);
			const rowIndex = this._numberOfWeeks(day, firstWeekday) - 1;

			dayRowsElements[rowIndex].append(dayComponent.element);

			return dayComponent;
		});

		const lastWeekDays = dayRowsElements[dayRowsElements.length - 1].querySelectorAll('.iris-day');

		// generate calendar days spacer
		if (lastWeekDays.length < 7) {
			utility.range(7 - lastWeekDays.length).forEach((day) => {
				const dayElement = document.createElement('div');
				dayElement.classList.add('iris-calendar__days-spacer');
				dayRowsElements[dayRowsElements.length - 1].appendChild(dayElement);
			});
		}
		this.element.append(daysContainerElement);
		this._dispatchEvent('iris.calendar.generate.end', { component: this });
	}

	private _generateDaysOfTheWeekNames() {
		const fullDayNames: IFullDayNames = {
			sun: 'Sunday',
			mon: 'Monday',
			tue: 'Tuesday',
			wed: 'Wednesday',
			thu: 'Thursday',
			fri: 'Friday',
			sat: 'Saturday'
		};

		// build NameOfTheWeekComponent
		const namesOfTheWeek = document.createElement('div');
		namesOfTheWeek.classList.add('iris-calendar__days-header');
		namesOfTheWeek.setAttribute('role', 'row');

		dateUtility.findDayOfTheWeek().forEach(function(dayOfWeek) {
			const weekday = document.createElement('span');
			weekday.classList.add('iris-calendar__day-name');
			weekday.setAttribute('role', 'columnheader');
			weekday.innerHTML = `<span class="sr-only">${fullDayNames[dayOfWeek]}</span><span aria-hidden="true">${dayOfWeek}</span>`;
			namesOfTheWeek.append(weekday);
		});

		return namesOfTheWeek;
	}


	// Public getters and setters
	// =============================================================================
	get date(): { month: number, year: number } {
		return { month: this._currentMonth, year: this._currentYear };
	}

	set date(dateObj: { month: number, year: number }) {
		// Remove the current days and title to be regenerated
		this.element.querySelector('.iris-calendar__days').remove();

		// Set the updated month and year for the generation functions
		const date = new Date(dateObj.year, dateObj.month);
		this._currentMonth = date.getUTCMonth();
		this._currentYear = date.getUTCFullYear();

		// Re-create the header and days of the updated month
		this._updateMonthHeader();
		this._generateMonthRowsAndDays();
	}

	set events(eventsToAddOrUpdate: ICalendarDayEvent[]) {
		// Iterate over the list of events to update
		eventsToAddOrUpdate.forEach((newOrUpdatedEvent) => {
			// TODO: Try to normalize these dates by converting to a date object before comparing.
			const eventOnTheSameDayIndex = this._events.findIndex((storedEvent) => storedEvent.eventDate === newOrUpdatedEvent.eventDate);

			// If there is already an event with the same date as one we are attempting to update, merge with the existing one
			if (eventOnTheSameDayIndex >= 0) {
				this._events[eventOnTheSameDayIndex] = Object.assign({}, this._events[eventOnTheSameDayIndex], newOrUpdatedEvent);
				return;
			}

			this._events.push(newOrUpdatedEvent);
		});

		const eventsInCurrentMonth = eventsToAddOrUpdate.filter((event) => {
			const convertedEventDate = dateUtility.convert(event.eventDate) as Date;

			return convertedEventDate.getUTCMonth() === this._currentMonth;
		});

		if (eventsInCurrentMonth.length > 0) {
			// TODO: Instead of rebuilding the entire calendar, just set the event object inside the matching children.
			this.element.querySelector('.iris-calendar__days').remove();
			this._generateMonthRowsAndDays();
		}
	}

	get childrenComponents() {
		return this._dayComponents;
	}


	// Private helper methods
	// =============================================================================
	private _daysInMonth(month: number, year: number) {
		return new Date(year, month + 1, 0).getUTCDate();
	}

	private _nameOfMonth(month: number) {
		return dateUtility.findMonthName(month);
	}

	private _firstDayOfMonth(month: number, year: number) {
		return new Date(year, month, 1).getDay();
	}

	private _numberOfWeeks(firstWeekday: number, numberOfDays: number) {
		return Math.ceil((numberOfDays + firstWeekday) / 7);
	}

	private _updateMonthHeader = () => {
		this._calendarTitleElement.innerHTML = `${this._nameOfMonth(this._currentMonth)} ${this._currentYear}`;

		if (this._options.startDateLimit) {
			const startDate = dateUtility.convert(this._options.startDateLimit) as Date;

			const isCurrentYearEqualToStartDateYear = this._currentYear === startDate.getUTCFullYear();
			const isCurrentMonthLessThanOrEqualToStartDate = this._currentMonth <= startDate.getUTCMonth();
			const isCurrentYearLessThanStartDate = this._currentYear < startDate.getUTCFullYear();

			if (isCurrentYearEqualToStartDateYear && isCurrentMonthLessThanOrEqualToStartDate || isCurrentYearLessThanStartDate) {
				this._prevMonthButtonElement.disabled = true;
			} else {
				this._prevMonthButtonElement.disabled = false;
			}
		}

		if (this._options.endDateLimit) {
			const endDate = dateUtility.convert(this._options.endDateLimit) as Date;

			const isCurrentYearEqualToEndDateYear = this._currentYear === endDate.getUTCFullYear();
			const isCurrentMonthGreaterThanOrEqualToEndDate = this._currentMonth >= endDate.getUTCMonth();
			const isCurrentYearGreaterThanEndDate = this._currentYear > endDate.getUTCFullYear();

			if (isCurrentYearEqualToEndDateYear && isCurrentMonthGreaterThanOrEqualToEndDate || isCurrentYearGreaterThanEndDate) {
				this._nextMonthButtonElement.disabled = true;
			} else {
				this._nextMonthButtonElement.disabled = false;
			}
		}
	}

	private _mergeEventWithDefaults = (eventObject: ICalendarDayEvent) => {
		const optionDefaults: ICalendarDayEvent = {
			eventDate: null,
			avatarIconClass: this._options.defaultAvatarIconClass,
			eventLabel: null,
		};

		return Object.assign({}, optionDefaults, eventObject);
	}


	// Event Handlers
	// =============================================================================
	private _previousMonthClickHandler = () => {
		if (this._currentMonth === 0) {
			this._currentMonth = 11;
			this._currentYear--;
		} else {
			this._currentMonth--;
		}

		this.date = { month: this._currentMonth, year: this._currentYear };

		dom.dispatchEvent(this.element, this._options.previousMonthClickEventName, {
			component: this,
			currentMonth: this._currentMonth,
			currentYear: this._currentYear,
		});
	}

	private _nextMonthClickHandler = () => {
		if (this._currentMonth === 11) {
			this._currentMonth = 0;
			this._currentYear++;
		} else {
			this._currentMonth++;
		}

		this.date = { month: this._currentMonth, year: this._currentYear };

		dom.dispatchEvent(this.element, this._options.nextMonthClickEventName, {
			component: this,
			currentMonth: this._currentMonth,
			currentYear: this._currentYear,
		});
	}

	private _calendarChildSelectedHandler = (event: CustomEvent) => {
		this._dayComponents.forEach((day) => {
			if (event.detail.component !== day && day.selected === true) {
				day.selected = false;
			}
		});

		dom.dispatchEvent(this.element, this._options.selectedEventDayName, {
			component: this,
			selectedDay: event.detail.component,
			event: event.detail.event
		});
	}

	private _calendarKeyboardNavigate = (event: CustomEvent) => {
		const currentIndex = this._dayComponents.findIndex((x) => x.element === event.detail.component.element);
		const direction = event.detail.direction;
		const navigateAmount: INavigationAmount = {
			left: currentIndex - 1,
			right: currentIndex + 1,
			up: currentIndex - 7,
			down: currentIndex + 7
		};
		const targetDayComponent = this._dayComponents[navigateAmount[direction]];

		if (targetDayComponent) {
			targetDayComponent.element.querySelector('button').focus();
		}
	}

	private _addEventListeners() {
		this._prevMonthButtonElement.addEventListener('click', this._previousMonthClickHandler);
		this._nextMonthButtonElement.addEventListener('click', this._nextMonthClickHandler);
		this.element.addEventListener('_calendarDaySelected', this._calendarChildSelectedHandler);
		this.element.addEventListener('_calendarKeyboardNavigate', this._calendarKeyboardNavigate);
	}


	// Factory Functions
	// =============================================================================
	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '.iris-calendar',
		componentName: 'CalendarComponent'
	};

	private static _defaultComponentOptions: ICalendarOptions = {
		startDateLimit: null,
		endDateLimit: null,
		calendarEvents: [],
		defaultAvatarIconClass: null,
		selectedEventDayName: 'iris.calendar.day.selected',
		previousMonthClickEventName: 'iris.calendar.previous.month',
		nextMonthClickEventName: 'iris.calendar.next.month',
	};

	public static factory = new GenericComponentFactory<CalendarComponent, ICalendarOptions>(CalendarComponent, CalendarComponent._factoryOptions, CalendarComponent._defaultComponentOptions);
}

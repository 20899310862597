import { Component, IComponentOptions } from '../component';
import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import { simulateClickKeypressHandler } from '../keypress-handler';

// tslint:disable-next-line no-empty-interface
export interface ISyntheticButtonComponentOptions extends IComponentOptions { }

export class SyntheticButtonComponent extends Component {
	private _previousRole: string = null;

	constructor(element: HTMLElement, options?: ISyntheticButtonComponentOptions) {
		super(element, options);

		if (this.element.tagName !== 'BUTTON') {
			this._previousRole = this.element.getAttribute('role') || null;

			if (this._previousRole === null) {
				this.element.setAttribute('role', 'button');
			}

			this.element.setAttribute('tabIndex', '0');
			this.element.addEventListener('keydown', this._keypressHandler);
		}
	}

	public destroy() {
		if (this.element.tagName !== 'BUTTON') {

			if (this._previousRole === null) {
				this.element.removeAttribute('role');
			} else {
				this.element.setAttribute('role', this._previousRole);
			}

			this.element.removeAttribute('tabIndex');
			this.element.removeEventListener('keydown', this._keypressHandler);
		}
	}

	private _keypressHandler = simulateClickKeypressHandler();

	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '[data-button-like]',
		componentName: 'SyntheticButtonComponent'
	};

	private static _defaultComponentOptions: ISyntheticButtonComponentOptions = {
		idPrefix: 'iris_synthetic_button',
	};

	public static factory = new GenericComponentFactory<SyntheticButtonComponent, ISyntheticButtonComponentOptions>(SyntheticButtonComponent, SyntheticButtonComponent._factoryOptions, SyntheticButtonComponent._defaultComponentOptions);
}

import { IIrisLogger, logger } from './logger';

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// @source https://davidwalsh.name/javascript-debounce-function
// @author David Walsh
export function debounce(func: (...args: any[]) => any | void, wait: number, immediate: boolean = false) {
	let timeout: any;
	return function(..._a: any[]) {
		const context = this;
		const args = arguments;
		const later = function() {
			timeout = null;
			if (!immediate) { func.apply(context, args); }
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) { func.apply(context, args); }
	};
}

const tokenRegex = /{(\d+)}/g;
export function format(string: string, ...values: any[]) {
	return string.replace(tokenRegex, (match, number) => {
		return typeof values[number] !== 'undefined' ? values[number] : match;
	});
}

export function generateUniqueId(prefix: string, existingIds?: string[]): string {
	const id = prefix + '_' + (new Date()).getTime();
	const exists = existingIds ? existingIds.indexOf(id) > -1 : document && document.getElementById(id);

	if (exists) {
		return generateUniqueId(prefix, existingIds);
	}

	return id;
}

export function getOrdinal(day: number) {
	const suffixes = ['th', 'st', 'nd', 'rd'];
	const value = day % 100;

	return day + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
}

export function isString(str: any): boolean {
	return (typeof str === 'string' || str instanceof String);
}

export function joinAttributeList(list: string[]): string {
	return list
		.map((item) => item.trim())
		.join(' ');
}

/* tslint:disable: unified-signatures */
export function range(start: number, end: number, step: number): number[];
export function range(start: number, end: number): number[];
export function range(range: number): number[];
export function range(rangeOrStart: number, maybeEnd?: number, step: number = 1): number[] {
	const start = maybeEnd ? rangeOrStart : 0;
	const end = maybeEnd || rangeOrStart;

	return (Array.apply(null, { length: (end - start) / step }) as number[]).map((_n, index) => {
		return (index * step) + start;
	});
}
/* tslint:enable */

export function splitAttributeToList(attr: string): string[] {
	const attribute = (attr && attr.trim()) || '';
	return attribute ? attribute.split(/\s+/) : [];
}

export function toArray(arrayLikeObject: any): any[] {
	if (!arrayLikeObject) {
		// tslint:disable-next-line
		console.warn('The object passed in was either null or undefined. Returning an empty array.');
		return [];
	}

	const result = Array.prototype.slice.call(arrayLikeObject);

	// if the slice comes back empty and there is not a length property
	// we can assume safely it's not an arrayLikeObject and just push it in.
	// if there is a length property then we will just return the empty array.
	if (result.length === 0 && typeof arrayLikeObject.length === 'undefined') {
		result.push(arrayLikeObject);
	}

	return result;
}

export const dispatchIrisEvent = (item: HTMLElement, eventName: string, messageObject: IIrisLogger, detail?: any): void => {
	const event = new CustomEvent(eventName, {
		detail: detail || item,
	});

	item.dispatchEvent(event);

	logger({
		component: messageObject.component,
		message: `Dispatching event ${event.type}`,
		type: messageObject.type,
	});
};

export function isTouchDevice() {
	return 'ontouchstart' in document.documentElement;
}

export function areArraysEqual(arr1: any[], arr2: any, strict: boolean = false): boolean {
	if (arr1.length !== arr2.length) {
		return false;
	}

	if (strict) {
		for (let i = arr1.length; i--;) {
			if (arr1[i] !== arr2[i]) {
				return false;
			}
		}

		return true;
	}

	return arr1.every((item1: any) => !!arr2.find((item2: any) => item1 === item2));
}

// returns a function that will run the passed in function only once,
// otherwise the result of that function will be returned.
// @source https://davidwalsh.name/javascript-once
// @author David Walsh
export function once(fn: (..._arguments: any[]) => any, context = this) {
	let result: any;
	let cb = fn;

	// tslint:disable-next-line: only-arrow-functions
	return function(..._arguments: any[]) {
		if (cb) {
			result = fn.apply(context, arguments);
			cb = null;
		}

		return result;
	};
}

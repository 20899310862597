import * as utility from './utility';

export function ancestorsOfElement(element: Element, query?: string): Element[] {
	const results = [];
	let el = element;

	do {
		if (!query || el.matches(query)) {
			results.push(el);
		}

		el = el.parentElement;
	} while (el);

	return results;
}

export function classListContainsAll(element: HTMLElement, classNameOrArray: string | string[]) {
	const classNames: string[] = utility.toArray(classNameOrArray);
	const filteredClasses = classNames.filter((className) => element.classList.contains(className));

	return filteredClasses.length === classNames.length;
}

export function classListContainsAny(element: HTMLElement, classNameOrArray: string | string[]) {
	const classNames: string[] = utility.toArray(classNameOrArray);
	const filteredClasses = classNames.filter((className) => element.classList.contains(className));

	return filteredClasses.length > 0;
}

export function createElementsFromHTML(htmlString: string): HTMLCollection {
	const div = document.createElement('DIV');
	div.innerHTML = htmlString.trim();

	return div.children;
}

// Returns an HTMLElement that is nearby the specified element described by the query string.
// The function is trying to be helpful and determine your
// intention as a user. If you use an ID we want to try from the document
// scope because we assume you know exactly what you want, otherwise check
// around the element to see if there is something close by.
// this differs from Element.closest in that it's a limited scope search and won't
// continue branching out to find an element.

export function relatedElementByQuery(element: HTMLElement, query: string): HTMLElement {
	if (!query) {
		return null;
	}

	// if query is an ID we assume it's directly related and search the document for it.
	if (query.indexOf('#') === 0) {
		return document.querySelector(query) as HTMLElement;
	}

	// if the next sibiling matches then it is the closest descendant.
	if (element.nextElementSibling && element.nextElementSibling.matches(query)) {
		return element.nextElementSibling as HTMLElement;
	}
	if (element.previousElementSibling && element.previousElementSibling.matches(query)) {
		return element.previousElementSibling as HTMLElement;
	}

	// if the closest siblings to the element don't match then lets check all of it's siblings!
	return siblingsOfElement(element, query)[0] as HTMLElement || null;
}

export function ready(callback: () => void): void {
	const contentLoadedHandler = () => {
		document.removeEventListener('DOMContentLoaded', contentLoadedHandler);
		callback();
	};

	if (document.readyState === 'complete' ||
		(document.readyState !== 'loading' &&
			!(document.documentElement as any).doScroll)) {
		callback();
	} else {
		document.addEventListener('DOMContentLoaded', contentLoadedHandler);
	}
}

export function scrollIntoViewIfNeeded(element: HTMLElement, centerIfNeeded: boolean = true) {
	function withinBounds(value: number, min: number, max: number, extent: number) {
		if (false === centerIfNeeded || max <= value + extent && value <= min + extent) {
			return Math.min(max, Math.max(min, value));
		} else {
			return (min + max) / 2;
		}
	}

	function makeArea(left: number, top: number, width: number, height: number) {
		return {
			left,
			top,
			width,
			height,
			right: left + width,
			bottom: top + height,
			translate(x: number, y: number) {
				return makeArea(x + left, y + top, width, height);
			},
			relativeFromTo(lhsElement: HTMLElement, rhsElement: HTMLElement) {
				let newLeft = left;
				let newTop = top;
				let lhs = lhsElement.offsetParent as HTMLElement;
				let rhs = rhsElement.offsetParent as HTMLElement;
				if (lhs === rhs) {
					return area;
				}
				for (; lhs; lhs = lhs.offsetParent as HTMLElement) {
					newLeft += lhs.offsetLeft + lhs.clientLeft;
					newTop += lhs.offsetTop + lhs.clientTop;
				}
				for (; rhs; rhs = rhs.offsetParent as HTMLElement) {
					newLeft -= rhs.offsetLeft + rhs.clientLeft;
					newTop -= rhs.offsetTop + rhs.clientTop;
				}
				return makeArea(newLeft, newTop, width, height);
			},
		};
	}

	let parent = element.parentNode;
	let area = makeArea(
		element.offsetLeft, element.offsetTop,
		element.offsetWidth, element.offsetHeight);

	while (parent instanceof HTMLElement) {
		const clientLeft = parent.offsetLeft + parent.clientLeft;
		const clientTop = parent.offsetTop + parent.clientTop;

		// Make area relative to parent's client area.
		area = area.
			relativeFromTo(element, parent).
			translate(-clientLeft, -clientTop);

		parent.scrollLeft = withinBounds(
			parent.scrollLeft,
			area.right - parent.clientWidth, area.left,
			parent.clientWidth);

		parent.scrollTop = withinBounds(
			parent.scrollTop,
			area.bottom - parent.clientHeight, area.top,
			parent.clientHeight);

		// Determine actual scroll amount by reading back scroll properties.
		area = area.translate(clientLeft - parent.scrollLeft,
			clientTop - parent.scrollTop);
		element = parent;
		parent = element.parentNode;
	}
}

export function siblingsOfElement(element: Element, query?: string): Element[] {
	const results = [];

	if (element.parentElement) {
		let el = element.parentElement.firstElementChild;

		do {
			if (!query || el.matches(query)) {
				results.push(el);
			}

			el = el.nextElementSibling;
		} while (el);
	}

	return results;
}

export function getViewPortBoundingRect(): ClientRect {
	const x = window.screenX || 0;
	const y = window.screenY || 0;
	const width = window.innerWidth;
	const height = window.innerHeight;
	const top = y;
	const left = x;
	const bottom = y + height;
	const right = x + width;

	return { x, y, width, height, top, left, bottom, right } as ClientRect;
}

export function hasScrollbar(element: HTMLElement = document.documentElement, type: 'vertical' | 'horizontal' | 'both' | 'either' = 'vertical') {

	if (type === 'vertical') {
		return element.scrollHeight > element.clientHeight;
	}

	if (type === 'horizontal') {
		return element.scrollWidth > element.clientWidth;
	}

	if (type === 'both') {
		return element.scrollHeight > element.clientHeight && element.scrollWidth > element.clientWidth;
	}

	return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

export function getScrollbarWidth() {
	const outer = document.createElement('div');
	outer.style.visibility = 'hidden';
	outer.style.width = '100px';
	document.body.appendChild(outer);

	const widthNoScroll = outer.offsetWidth;

	outer.style.overflow = 'scroll';
	const inner = document.createElement('div');
	inner.style.width = '100%';
	outer.appendChild(inner);

	const widthWithScroll = inner.offsetWidth;

	outer.parentNode.removeChild(outer);

	return widthNoScroll - widthWithScroll;
}

export function setElementAttributes(element: HTMLElement, map: { [key: string]: string }): void {
	Object.keys(map).forEach((key) => {
		element.setAttribute(key, map[key]);
	});
}

export function dispatchEvent(element: HTMLElement, eventName: string, detail: any = {}, bubbles: boolean = true, cancelable: boolean = true): void {
	const event = new CustomEvent(eventName, { bubbles, cancelable, detail });
	element.dispatchEvent(event);
}

export function handleOnce(element: HTMLElement, eventName: string, eventListener: EventListener) {
	const handler = function(event: Event) {
		element.removeEventListener(eventName, handler);
		return eventListener.bind(this)(event);
	};

	element.addEventListener(eventName, handler);
}

// In IE11 when you set innerHTML = '' on a parent element it also clears out the innerHTML on all child nodes.
// This method prevents that by removing each child node that is under the parent indivdually
// https://stackoverflow.com/questions/25167593/why-does-ie-discard-the-innerhtml-children-of-a-dom-element-after-dom-changes
export function removeChildNodes(parentElement: HTMLElement): void {
	utility.toArray(parentElement.childNodes).forEach((childElement) => childElement.remove());
}


// checks to see if an element takes space.
// https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js
export function isVisible(element: HTMLElement): boolean {
	return !!( element.offsetWidth || element.offsetHeight || element.getClientRects().length );
}

export type IrisLoggerLevel = 'trace' | 'info' | 'log' | 'warn' | 'error';

export interface IIrisLogger {
	component?: string;
	message: string;
	tip?: string;
	type: IrisLoggerLevel;
}

// Check the local storage for a TRACE key to enable trace messages.
// TODO: Make this setting's value a bit more flexible.
const logTraceMessages = window.localStorage && window.localStorage.getItem('irisTrace') === 'true';

export function logger(messageObject: IIrisLogger) {
	const componentString = messageObject.component ? ` (${messageObject.component})` : '';
	let message = `IRIS:${componentString} ${messageObject.message}`;

	switch (messageObject.type) {
		case 'trace':
			if (logTraceMessages) {
				message = `TRACE || ${message}`;

				// tslint:disable-next-line no-console
				console.log(message);
			}
			break;

		case 'info':
			// tslint:disable-next-line no-console
			console.info(message);
			break;

		case 'log':
			// tslint:disable-next-line no-console
			console.log(message);
			break;

		case 'warn':
			// tslint:disable-next-line no-console
			console.warn(message);
			break;

		case 'error':
			// tslint:disable-next-line no-console
			console.error(new Error(message));
			break;

		default:
			// tslint:disable-next-line no-console
			console.warn('Message was of an unknown type. Something went wrong.');
			break;
	}

	if (messageObject.tip) {
		// tslint:disable-next-line no-console
		console.info(`TIP:  ${messageObject.tip}`);
	}
}

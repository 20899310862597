import { Component, IComponentOptions } from '../component';
import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import * as utility from '../utility';

const PLACEMENT = {
	top: 'top',
	right: 'right',
	bottom: 'bottom',
	left: 'left',
};

const INDICATOR_POSITION = {
	center: 'center',
	end: 'end',
	start: 'start',
	none: 'none',
};

const SIZE = {
	auto: 'auto',
	small: 'small',
	medium: 'medium',
	large: 'large',
};

// tslint:disable-next-line no-empty-interface
export interface ITooltipComponentOptions extends IComponentOptions { }

export class TooltipComponent extends Component {
	private _tooltipElement: HTMLElement;
	private _tooltipContentElement: HTMLElement;
	private _popper: Popper;
	private _focused: boolean = false;
	private _hovered: boolean = false;
	private _mutationObserver: MutationObserver = new MutationObserver(() => {
		if (this._popper) {
			this._popper.options.placement = this.placement as Popper.Placement;
		}

		this.tooltipElement.setAttribute('x-indicator', this.indicator);
		this.tooltipElement.setAttribute('x-size', this.size);
		this._tooltipContentElement.innerText = this.content;
	});

	constructor(element: HTMLElement, options?: ITooltipComponentOptions) {
		super(element, options);

		this._tooltipElement = document.createElement('span') as HTMLElement;
		this._tooltipElement.classList.add('iris-tooltip', 'iris-tooltip--collapsed');
		this._tooltipElement.setAttribute('x-expanded', 'false');
		this._tooltipElement.setAttribute('role', 'tooltip');
		this._tooltipElement.setAttribute('x-indicator', this.indicator);
		this._tooltipElement.setAttribute('x-size', this.size);
		this._tooltipElement.id = utility.generateUniqueId('iris_tooltip');
		this._tooltipElement.addEventListener('transitionend', this._transitionEndHandler);

		this._tooltipContentElement = document.createElement('span') as HTMLElement;
		this._tooltipContentElement.classList.add('iris-tooltip__content');
		this._tooltipContentElement.innerText = this.content;

		this._tooltipElement.append(this._tooltipContentElement);
		document.body.append(this._tooltipElement);

		this.element.setAttribute('aria-describedby', this.tooltipElement.id);
		this.element.addEventListener('mouseenter', this._mouseEnterHandler);
		this.element.addEventListener('mouseleave', this._mouseLeaveHandler);
		this.element.addEventListener('focus', this._focusHandler);
		this.element.addEventListener('blur', this._blurHandler);

		const elementType = this.element.tagName.toUpperCase();
		const isInteractiveType = ['BUTTON', 'A', 'INPUT', 'SELECT'].find((type) => elementType === type);

		if (!isInteractiveType && !this.element.hasAttribute('role')) {
			this.element.setAttribute('role', 'group');
		}

		if (!isInteractiveType) {
			this.element.setAttribute('tabindex', '0');
		}

		this._mutationObserver.observe(this.element, { attributes: true });
	}

	get expanded(): boolean {
		return this.tooltipElement.getAttribute('x-expanded') === 'true';
	}

	set expanded(bool: boolean) {
		const pastExpanded = this.expanded;

		if (!this._popper) {
			this._popper = new Popper(this.element, this._tooltipElement, {
					placement: this.placement as Popper.Placement,
			});
		}

		if (bool === pastExpanded) {
			return;
		}

		this._cleanClasses();
		this.tooltipElement.setAttribute('x-expanded', bool.toString());
		if (bool) {
			this.tooltipElement.classList.add('iris-tooltip--expand-start');
			this._popper.scheduleUpdate();
			window.setTimeout(() => {
				this.tooltipElement.classList.remove('iris-tooltip--expand-start');
				this.tooltipElement.classList.add('iris-tooltip--expanding');
			}, 0);

			return;
		}

		this.tooltipElement.classList.add('iris-tooltip--collapse-start');
		window.setTimeout(() => {
			this.tooltipElement.classList.remove('iris-tooltip--collapse-start');
			this.tooltipElement.classList.add('iris-tooltip--collapsing');
		}, 0);
	}

	get placement(): string {
		let placement = Object.values(PLACEMENT).find((value) => value === this.element.getAttribute('data-placement')) || 'auto';
		const indicator = this.indicator;

		if (indicator === 'start' || indicator === 'end') {
			placement = placement + '-' + indicator;
		}

		return placement;
	}

	get indicator(): string {
		return Object.values(INDICATOR_POSITION).find((value) => value === this.element.getAttribute('data-indicator')) || 'center';
	}

	get size(): string {
		return Object.values(SIZE).find((value) => value === this.element.getAttribute('data-size')) || 'auto';
	}

	get content(): string {
		return this.element.getAttribute('data-tooltip-content');
	}

	public get tooltipElement(): HTMLElement {
		return this._tooltipElement;
	}

	public destroy() {
		this._mutationObserver.disconnect();

		if (this._popper) {
			this._popper.destroy();
		}

		if (this.element) {
			this.element.removeEventListener('mouseenter', this._mouseEnterHandler);
			this.element.removeEventListener('mouseleave', this._mouseLeaveHandler);
			this.element.removeEventListener('focus', this._focusHandler);
			this.element.removeEventListener('blur', this._blurHandler);
			this.element.removeAttribute('aria-describedby');
		}

		if (this.tooltipElement) {
			this.tooltipElement.remove();
		}
	}

	private _cleanClasses() {
		const classNames = [
			'iris-tooltip--collapse-start',
			'iris-tooltip--collapsing',
			'iris-tooltip--collapsed',
			'iris-tooltip--expand-start',
			'iris-tooltip--expanding',
			'iris-tooltip--expanded',
		];
		this.tooltipElement.classList.remove(...classNames);
	}

	private _mouseEnterHandler = (event: Event) => {
		this._hovered = true;
		this._displayTooltip();
	}

	private _mouseLeaveHandler = (event: Event) => {
		this._hovered = false;
		this._displayTooltip();
	}

	private _focusHandler = (event: Event) => {
		this._focused = true;
		this._displayTooltip();
	}

	private _blurHandler = (event: Event) => {
		this._focused = false;
		this._displayTooltip();
	}

	private _displayTooltip() {
		this.expanded = this._hovered || this._focused;
	}

	private _transitionEndHandler = (event: Event) => {
		if (event.target !== this._tooltipElement) {
			return;
		}

		const bool = this.expanded;

		if (bool) {
			this.tooltipElement.classList.remove('iris-tooltip--expanding');
			this.tooltipElement.classList.add('iris-tooltip--expanded');
		} else {
			this.tooltipElement.classList.remove('iris-tooltip--collapsing');
			this.tooltipElement.classList.add('iris-tooltip--collapsed');
		}
	}

	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '[data-tooltip-content]',
		componentName: 'TooltipComponent'
	};

	private static _defaultComponentOptions: ITooltipComponentOptions = {
		idPrefix: 'iris_tooltip',
	};

	public static PLACEMENT = PLACEMENT;
	public static INDICATOR_POSITION = INDICATOR_POSITION;
	public static SIZE = SIZE;
	public static factory = new GenericComponentFactory<TooltipComponent, ITooltipComponentOptions>(TooltipComponent, TooltipComponent._factoryOptions, TooltipComponent._defaultComponentOptions);
}

import { Component } from './component';
import { logger } from './logger';
import { toArray } from './utility';

export interface IComponentCache {
    [key: string]: any[];
}

export class ComponentManager {
    protected _components: IComponentCache = {};

    public get components() {
        return this._components;
    }

    public getComponentsByType(componentName: string) {
        const componentsOfType: IComponentCache = {};

        Object.values(this._components).forEach((componentArray) => {
            componentArray.forEach((component) => {
                const irisId = component.element.dataset.irisId;

                if (component.getOptions().componentName === componentName) {
                    if (!componentsOfType[irisId]) {
                        componentsOfType[irisId] = [];
                    }
                    componentsOfType[irisId].push(component);
                }
            });
        });

        return componentsOfType;
    }

    public add(component: Component): void {
        const irisId = component.element.dataset.irisId;

        if (!irisId) {
            logger({
                component: 'Manager',
                message: 'No iris id on the element passed in.',
                type: 'warn'
            });
        }

        if (!this._components[irisId]) {
            this._components[irisId] = [];
        }

        this._components[irisId].push(component);
    }

    public destroyComponent(nodes: any, componentName: string) {
        const destroyedComponents: any[] = [];

        toArray(nodes).forEach((element: HTMLElement) => {
            const irisId = element.dataset.irisId;

            if (!this._components[irisId]) {
                logger({
                    component: 'Manager',
                    message: 'The irisId was not found in the registry.',
                    type: 'warn'
                });

                return null;
            }

            const componentToRemove = this.findInstanceForElement(irisId, componentName);
            if (!componentToRemove) {
                logger({
                    component: 'Manager',
                    message: 'A matching component was not found in the registry.',
                    type: 'warn'
                });

                return null;
            }

            // First destroy the component
            // NOTE: This could initiate another component destroy as well (i.e. collapsable and synthetic button)
            componentToRemove.destroy();

            // Next calculate the index of the component in the registry.
            // NOTE: This MUST happen after the destroy in case the index changes by removing a child component.
            const componentIndex = this._components[irisId].indexOf(componentToRemove);

            destroyedComponents.push(this._components[irisId].splice(componentIndex, 1)[0]);

            if (this._components[irisId].length === 0) {
                delete this._components[irisId];

                delete element.dataset.irisId;
                element.removeAttribute('data-iris-id');
            }
        });

        return destroyedComponents;
    }

    public disposeZombieInstances(componentName: string) {
        const irisIds = Object.keys(this._components);

        if (irisIds.length === 0) {
            logger({
                component: 'Manager',
                message: 'There are no irisIds to search through.',
                type: 'info'
            });

            return []; // Throw the warning and return an empty array.
        }

        const cleanedComponents: Component[] = [];

        irisIds.forEach((irisId: string) => {
            // First search the DOM for the element to see if it exists
            const element = document.querySelector(`[data-iris-id="${irisId}"]`);

            // If it doesn't, it means that the element was removed from the DOM.
            // The most likely cause is a JavaScript framework of some kind.
            if (!element) {
                // If no components are listed under the irisId, it means we have most likely
                // already removed that key from a previous component's destroy method.
                // EX: Drawer destroying a synthetic button.
                // When this happens, give a log error and move on.
                if (!this._components[irisId]) {
                    logger({
                        component: 'Manager',
                        message: `The component listing for Iris ID: ${irisId} doesn't exist.`,
                        type: 'info'
                    });

                    return;
                }

                this._components[irisId].forEach((component: Component) => {
                    if (componentName === component.getOptions().componentName) {
                        this.destroyComponent(component.element, componentName);
                        cleanedComponents.push(component);
                    }
                });
            }
        });

        return cleanedComponents;
    }

    public findInstanceForElement(irisId: string, componentName: string) {
        if (!this._components[irisId]) {
            return null;
        }

        return this._components[irisId].find((component: Component) => componentName === component.getOptions().componentName || null);
    }

    public static defaultManager = new ComponentManager();
}

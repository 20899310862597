import { Component, IComponentOptions } from '../component';
import { ComponentFactory } from '../component-factory';
import { createKeypressEventListener, IKeyPressHandlers } from '../keypress-handler';
import { RegionSettings } from '../regional';
import * as utility from '../utility';
import { LiveRegion } from './live-region';
import { TabComponent } from './tab';
import { TabNavigationComponent } from './tab-navigation';

export class TabListComponentFactory extends ComponentFactory<TabListComponent, ITabComponentOptions> {
	private _defaultComponentOptions: ITabComponentOptions = {
		leftIconClass: 'font-icon-angle-left',
		rightIconClass: 'font-icon-angle-right',
		idPrefix: 'iris_tabs',
	};

	constructor() {
		super((element: HTMLElement, options?: ITabComponentOptions) => {
			return new TabListComponent(element, Object.assign({ componentName: 'TabListComponent' }, this._defaultComponentOptions, options));
		}, { defaultQuerySelector: '.iris-tabs', componentName: 'TabListComponent' });
	}

	public componentForElement(element: HTMLElement): TabListComponent {

		let component = super.componentForElement(element);

		if (element && !component) {
			component = this.getTabListByTabElement(element);
		}

		return component;
	}

	public getTabListByTabElement(tabElement: HTMLElement): TabListComponent {
		// Grab the component listing and filter out any components that are not of the correct type
		const filteredComponentList = Object.values(this.components).map((componentArray) => {
			return componentArray.filter((component) => component.componentName === this.componentName);
		}).filter((potentiallyEmptyArray) => potentiallyEmptyArray.length > 0);

		// Merge each of the arrays together and then run a find.
		return [].concat.apply([], filteredComponentList)
			.find((tabListComponent: TabListComponent) => {
				return !!tabListComponent.tabs.find((tab) => tab.element.id === tabElement.id);
			}) || null;
	}
}

export interface ITabComponentOptions extends IComponentOptions {
	leftIconClass: string | string[];
	rightIconClass: string | string[];
}

export class TabListComponent extends Component {

	private _tabListElement: HTMLElement;
	private _tabInnerElement: HTMLElement;
	private _leftNavigation: TabNavigationComponent;
	private _rightNavigation: TabNavigationComponent;
	private _statusRegion: LiveRegion;
	private _tabs: TabComponent[] = [];
	private _mutationObserver: MutationObserver = new MutationObserver(() => {
		this._updateTabs();
	});

	constructor(element: HTMLElement, options?: ITabComponentOptions) {
		super(element, options);

		this._statusRegion = LiveRegion.globalStatusRegion;
		this._tabListElement = element.querySelector('.iris-tabs__list') as HTMLElement;
		this._tabInnerElement = element.querySelector('.iris-tabs__inner') as HTMLElement;
		this._leftNavigation = new TabNavigationComponent('left', 'iris-tabs__back-button', options.leftIconClass);
		this._rightNavigation = new TabNavigationComponent('right', 'iris-tabs__next-button', options.rightIconClass);

		this.tabListElement.setAttribute('role', 'tablist');
		this.tabListElement.addEventListener('activate', this._activateHandler);

		this.tabInnerElement.addEventListener('scroll', this._scrollHandler);

		this.element.addEventListener('navPressed', this._navPressedHandler);
		this.element.addEventListener('keydown', this._handleKeyPressListener);

		this.element.append(this._leftNavigation.element, this._rightNavigation.element);

		this._updateTabs();
		this._mutationObserver.observe(this.element, { childList: true });
	}

	public get tabListElement(): HTMLElement {
		return this._tabListElement;
	}
	public get tabInnerElement(): HTMLElement {
		return this._tabInnerElement;
	}
	public get tabs(): TabComponent[] {
		return this._tabs;
	}
	public get activeTab(): TabComponent {
		return this._tabs.find((tab) => tab.active);
	}
	public set activeTab(activeTab: TabComponent) {
		this.tabs.forEach((tab) => {
			if (activeTab && !activeTab.active && activeTab.id === tab.id) {
				tab.active = true;
			}

			if (!activeTab || (tab.active && activeTab.id !== tab.id)) {
				tab.active = false;
			}
		});
	}

	public findTabById(id: string): TabComponent {
		return this.tabs.find((tab) => tab.element.id === id) || null;
	}

	public destroy() {
		this._mutationObserver.disconnect();

		if (this._leftNavigation.element) {
			this._leftNavigation.element.remove();
		}

		if (this._rightNavigation.element) {
			this._rightNavigation.element.remove();
		}

		if (this.tabListElement) {
			this.tabListElement.removeEventListener('activate', this._activateHandler);
		}

		if (this.tabInnerElement) {
			this.tabInnerElement.removeEventListener('scroll', this._scrollHandler);
		}

		if (this.element) {
			this.element.removeEventListener('navPressed', this._navPressedHandler);
			this.element.removeEventListener('keydown', this._handleKeyPressListener);
		}

		this.tabs.forEach((tab) => {
			tab.destroy();
		});
	}

	private _scrollHandler = utility.debounce((event: Event) => {
		const targetElement = event.target as HTMLElement;
		if (targetElement.scrollLeft > 0) {
			targetElement.closest('.iris-tabs').classList.add('scrollable-left');
		} else if (targetElement.scrollLeft === 0) {
			targetElement.closest('.iris-tabs').classList.remove('scrollable-left');
		}

		if (targetElement.scrollLeft + targetElement.clientWidth >= targetElement.scrollWidth) {
			targetElement.closest('.iris-tabs').classList.remove('scrollable-right');
		} else if (targetElement.scrollLeft + targetElement.clientWidth < targetElement.scrollWidth && targetElement.scrollWidth > targetElement.clientWidth) {
			targetElement.closest('.iris-tabs').classList.add('scrollable-right');
		}
	}, 50);

	private _navPressedHandler = (event: CustomEvent) => {
		const tabInnerRect = this.tabInnerElement.getBoundingClientRect();
		const buttonElement = event.detail.component.element as HTMLElement;
		const direction = event.detail.direction;
		const tabstarts = this.tabs.map((tabComponent) => tabComponent.element.offsetLeft);
		const scrollWidth = this.tabInnerElement.scrollWidth;
		const amountToScroll = tabInnerRect.width;
		let positionToScroll = direction === 'right' ?
			this.tabInnerElement.scrollLeft + amountToScroll :
			this.tabInnerElement.scrollLeft - amountToScroll;

		if (positionToScroll <= 0) {
			positionToScroll = 0;
		} else if (positionToScroll >= scrollWidth) {
			positionToScroll = scrollWidth;
		} else {
			// find the tab that was peeking into view on either side
			if (direction === 'right') {
				positionToScroll = tabstarts.find((value, index, array) => {
					return index === array.length - 1 || (value < positionToScroll && array[index + 1] > positionToScroll);
				});
			} else {
				positionToScroll = tabstarts.reverse().find((value, index, array) => {
					return index === array.length - 1 || (value > positionToScroll && array[index + 1] < positionToScroll);
				});
			}

			// don't hide tab behind the scroll buttons
			positionToScroll = positionToScroll - buttonElement.offsetWidth;
		}

		this.tabInnerElement.scroll({
			left: positionToScroll,
			behavior: 'smooth',
		});
	}

	private _activateHandler = (event: CustomEvent) => {
		// IE likes to fire multiple events from a click handler.
		// One of those has detail: 0 for some odd reason.
		if (!event.detail) {
			return;
		}

		const activeTab = event.detail.component;
		this.activeTab = activeTab;
		this._statusRegion.broadcast(RegionSettings.format('tab.sr.selected', activeTab.text));
	}

	private _moveHighlightedTab(steps: number) {
		const highlightedTabIndex = this.tabs.findIndex((tab) => {
			return tab.element.contains(document.activeElement);
		});

		let movedIndex = highlightedTabIndex + steps;
		if (highlightedTabIndex === -1) {
			movedIndex = 0;
		} else if (movedIndex < 0) {
			movedIndex = this.tabs.length - 1;
		} else if (movedIndex >= this.tabs.length) {
			movedIndex = 0;
		}

		return movedIndex;
	}

	private _handleKeyPressListener = (event: Event) => {
		this._keypressEventListener(event);
	}

	private _keypressEventListener: EventListener = createKeypressEventListener({
		left: () => {
			const index = this._moveHighlightedTab(-1);
			this.tabs[index].element.focus();
		},
		right: () => {
			const index = this._moveHighlightedTab(1);
			this.tabs[index].element.focus();
		},
	} as IKeyPressHandlers) as EventListener;

	private _updateTabs() {
		const oldTabs = this._tabs;

		this._tabs = utility.toArray(this.element.querySelectorAll('.iris-tabs__list-item')).map((tabElement) => {
			const oldTab = oldTabs.find((tab) => tabElement.element.id === tab.id);
			return oldTab || new TabComponent(tabElement, this.tabListElement);
		});

		this.element.classList.toggle('scrollable-right', this.tabInnerElement.scrollWidth > this.tabInnerElement.clientWidth);
	}

	public static factory = new TabListComponentFactory();
}

import { Component, IComponentOptions } from '../component';
import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import * as dom from '../dom';
import { createKeypressEventListener, IKeyPressHandlers } from '../keypress-handler';
import { RegionSettings } from '../regional';
import { ITransitionOptions, transitionRunner } from '../transition-handler';
import * as utility from '../utility';
import { ListItem } from './list-item';
import { IListOptionConfig, ListOption } from './list-option';
import { LiveRegion } from './live-region';

// tslint:disable-next-line no-empty-interface
export interface IDropdownComponentOptions extends IComponentOptions { }

export class DropdownComponent extends Component {

	private _chevronElement: HTMLElement;
	private _captureInputElement: HTMLButtonElement;
	private _dataListElement: HTMLUListElement;
	private _displayListElement: HTMLUListElement;
	private _displayListContainerElement: HTMLDivElement;
	private _displayListContainerHeader: HTMLElement;
	private _displayListContainerTitle: HTMLElement;
	private _displayListContainerClose: HTMLElement;
	private _mutationObserver: MutationObserver;
	private _listMaxHeight: number;
	private _listOptions: ListOption[] = [];
	private _listOptionsBlackListedAttributes: string[] = [];
	private _placeholderElement: HTMLSpanElement;
	private _selectedValueElement: HTMLElement;
	private _statusRegion: LiveRegion;

	constructor(element: HTMLElement, options?: IDropdownComponentOptions) {
		super(element, options);

		this._statusRegion = LiveRegion.globalStatusRegion;

		this.element.addEventListener('click', this._clickHandler);
		this.element.addEventListener('_listitemselected', this._listItemSelectedHandler);
		this.element.addEventListener('keydown', this._keypressHandler);

		document.addEventListener('click', this._documentClickHandler);

		dom.ancestorsOfElement(this.element, 'form').forEach((formElement) => formElement.addEventListener('reset', this._formResetHandler));

		this.element.prepend(this.displayListContainerElement);
		this.element.prepend(this.chevronElement);
		this.element.prepend(this.selectedValueElement);
		this.element.prepend(this.captureInputElement);

		const style = window.getComputedStyle(this.displayListContainerElement);
		this._listMaxHeight = Number.parseInt(style.maxHeight, 10);

		this._updateListOptions();

		const selectedOptions = this.options.filter((option) => option.selected);
		this.value =  selectedOptions.map((option) => option.value);

		this.disabled = this.disabled; // normalizes the disabled flags on the element.
		this.validity = this.validity; // normalizes the invalid flags on the element

		this._updateComponentState();

		this._mutationObserver = new MutationObserver(this._mutationObserverHandler);
		this._mutationObserver.observe(this.dataListElement, { attributes: true, childList: true, subtree: true });
		this._mutationObserver.observe(this.element, { attributes: true });
	}

	// GETTERS/SETTERS
	// --------------------------------------------------------------------------

	get activeListOption(): ListOption {
		const activeDescendantId = this.displayListElement.getAttribute('aria-activedescendant');
		return this.options.find((option) => {
			return option.id === activeDescendantId;
		}) || null;
	}

	set activeListOption(listOption: ListOption) {
		if (this.activeListOption === listOption) {
			return;
		}

		this.options.forEach((option) => {
			option.active = !!listOption && (listOption.id === option.id);
		});

		const id = (listOption && listOption.id) || '';
		this.displayListElement.setAttribute('aria-activedescendant', id);

		if (listOption && this.expanded) {
			dom.scrollIntoViewIfNeeded(listOption.displayListItem.element, true);
			this._statusRegion.broadcast(RegionSettings.format(listOption.disabled ? 'dropdown.sr.disabled' : 'dropdown.sr.active', listOption.text));
		}
	}

	get captureInputElement(): HTMLButtonElement {
		if (this._captureInputElement) {
			return this._captureInputElement;
		}

		const captureInputElement = document.createElement('BUTTON') as HTMLButtonElement;
		captureInputElement.id = utility.generateUniqueId('iris_dropdown__capture_input');
		captureInputElement.classList.add('iris-dropdown__capture-input');

		dom.setElementAttributes(captureInputElement, {
			'tabindex': '0',
			'aria-expanded': 'false',
			'aria-owns': this.dataListElement.id,
			'aria-haspopup': 'listbox',
			'aria-describedby': this.placeholderElement.id,
			'type': 'button',
		});

		this._captureInputElement = captureInputElement;

		return this._captureInputElement;
	}

	get chevronElement(): HTMLElement {

		if (this._chevronElement) {
			return this._chevronElement;
		}

		utility.toArray(this.element.querySelectorAll('.iris-dropdown__chevron')).forEach((dupeElement) => dupeElement.remove());

		const chevronElement = document.createElement('DIV') as HTMLElement;
		chevronElement.classList.add('iris-chevron', 'iris-dropdown__chevron');

		this._chevronElement = chevronElement;

		return this._chevronElement;
	}

	get dataListElement(): HTMLUListElement {
		if (this._dataListElement) {
			return this._dataListElement;
		}

		const listElement = this.element.querySelector('.iris-options-list') as HTMLUListElement;
		listElement.id = listElement.id || utility.generateUniqueId('iris_options_list');
		this._dataListElement = listElement;

		return this._dataListElement;
	}

	get disabled(): boolean {
		return this.element.classList.contains('iris-dropdown--disabled') ||
			this.element.hasAttribute('disabled') ||
			(this.element.getAttribute('aria-disabled') || '').toLowerCase() === 'true';
	}

	set disabled(bool: boolean) {
		this.captureInputElement.setAttribute('aria-disabled', bool.toString());

		if (bool) {
			this.element.classList.add('iris-dropdown--disabled');
			this.element.setAttribute('disabled', 'disabled');
			this.captureInputElement.setAttribute('disabled', 'disabled');
		} else {
			this.element.classList.remove('iris-dropdown--disabled');
			this.element.removeAttribute('disabled');
			this.captureInputElement.removeAttribute('disabled');
		}
	}

	get displayListContainerElement(): HTMLDivElement {
		if (this._displayListContainerElement) {
			return this._displayListContainerElement;
		}

		utility.toArray(this.element.querySelectorAll('.iris-dropdown__display-list-container')).forEach((dupeElement) => dupeElement.remove());

		const element = document.createElement('DIV') as HTMLDivElement;
		element.classList.add('iris-dropdown__display-list-container');
		element.id = utility.generateUniqueId('iris_dropdown__display_list_container');
		element.append(this.displayListContainerHeader);
		element.append(this.displayListElement);

		this._displayListContainerElement = element;

		return this._displayListContainerElement;
	}

	get displayListContainerHeader(): HTMLElement {
		if (this._displayListContainerHeader) {
			return this._displayListContainerHeader;
		}

		utility.toArray(this.element.querySelectorAll('.iris-dropdown__display-list-header')).forEach((dupeElement) => dupeElement.remove());

		const element = document.createElement('DIV') as HTMLElement;
		element.classList.add('iris-dropdown__display-list-header');
		element.append(this.displayListContainerTitle);
		element.append(this.displayListContainerClose);

		this._displayListContainerHeader = element;

		return this._displayListContainerHeader;

	}

	get displayListContainerTitle(): HTMLElement {
		if (this._displayListContainerTitle) {
			return this._displayListContainerTitle;
		}

		utility.toArray(this.element.querySelectorAll('.iris-dropdown__display-list-header-title')).forEach((dupeElement) => dupeElement.remove());

		const element = document.createElement('SPAN') as HTMLSpanElement;
		element.classList.add('iris-dropdown__display-list-header-title', 'h2');

		this._displayListContainerTitle = element;

		return this._displayListContainerTitle;
	}

	get displayListContainerClose(): HTMLElement {
		if (this._displayListContainerClose) {
			return this._displayListContainerClose;
		}

		utility.toArray(this.element.querySelectorAll('.iris-dropdown__display-list-close')).forEach((dupeElement) => dupeElement.remove());

		const element = document.createElement('BUTTON') as HTMLButtonElement;
		element.classList.add('iris-button', 'iris-button--ghost', 'iris-dropdown__display-list-close');
		element.setAttribute('data-modifier', 'compressed');
		element.setAttribute('aria-label', 'Close List');
		element.innerHTML = '<span class="font-icon-cancel-x" aria-hidden="true"></span>';

		this._displayListContainerClose = element;

		return this._displayListContainerClose;
	}

	get displayListElement(): HTMLUListElement {
		if (this._displayListElement) {
			return this._displayListElement;
		}

		utility.toArray(this.element.querySelectorAll('.iris-dropdown__display-list')).forEach((dupeElement) => dupeElement.remove());

		const element = document.createElement('UL') as HTMLUListElement;
		element.classList.add('iris-dropdown__display-list');
		element.id = utility.generateUniqueId('iris_dropdown__display_list');
		element.setAttribute('role', 'listbox');
		element.setAttribute('tabIndex', '-1');
		this._displayListElement = element;

		return this._displayListElement;
	}

	get expanded(): boolean {
		return (this.captureInputElement.getAttribute('aria-expanded') || '').toLowerCase() === 'true';
	}

	set expanded(bool: boolean) {
		if (this.disabled) {
			bool = false;
		}

		if (bool === this.expanded) {
			return;
		}

		this._chevronElement.classList.toggle('iris-chevron--up', bool);
		this.captureInputElement.setAttribute('aria-expanded', bool.toString());

		this._manageExpandTransition(bool);

		if (bool) {
			this.displayListElement.focus();
		} else if (!bool && this.displayListElement === document.activeElement) {
			this.captureInputElement.focus();
		}
	}

	get listElement(): HTMLElement { return this.dataListElement; }

	get multiselect(): boolean {
		return this.element.hasAttribute('multiple');
	}

	set multiselect(bool: boolean) {
		if (bool) {
			this.element.setAttribute('multiple', '');
		} else {
			this.element.removeAttribute('multiple');
		}
	}

	get name(): string {
		return this.element.getAttribute('name');
	}

	set name(name: string) {
		this.element.setAttribute('name', name);
	}

	get options(): ListOption[] {
		return this._listOptions;
	}

	get placeholderElement(): HTMLSpanElement {
		if (this._placeholderElement) {
			return this._placeholderElement;
		}
		const placeholderElement = document.createElement('SPAN');
		placeholderElement.classList.add('iris-dropdown__placeholder');
		placeholderElement.id = utility.generateUniqueId('iris_dropdown__placeholder');

		this._placeholderElement = placeholderElement;

		return this._placeholderElement;
	}

	get required(): boolean {
		return this.element.hasAttribute('required');
	}

	set required(bool: boolean) {
		if (bool) {
			this.element.setAttribute('required', '');
		} else {
			this.element.removeAttribute('required');
		}

		this._manageHiddenInputs(this.selectedListOptions.map(((listOption) => listOption.value)));
	}

	get selectedListOptions(): ListOption[] {
		return this.options.filter((option) => option.selected);
	}

	set selectedListOptions(listOptions: ListOption[]) {
		listOptions = listOptions || [];
		const oldSelectedListOptions = this.selectedListOptions;

		const selectedListOptions = this.options.filter((listOption) => {
			return listOptions.find((selectedListOption) => selectedListOption.id === listOption.id && !listOption.disabled);
		});

		const sameListOptions = selectedListOptions.filter((selectedListOption) => {
			return oldSelectedListOptions.find((oldListOption) => oldListOption.id === selectedListOption.id);
		});
		// if the items has changed we will update them and fire an event.
		if (selectedListOptions.length !== oldSelectedListOptions.length || selectedListOptions.length !== sameListOptions.length) {
			this.options.forEach((listOption) => {
				listOption.selected = !!selectedListOptions.find((selectedListOption) => selectedListOption.id === listOption.id);
			});

			this._updateListOptions();
			this._manageHiddenInputs(selectedListOptions.map(((listOption) => listOption.value)));
			this._updateSelectedValueElement();

			this._dispatchDropdownEvent('change');
			this._dispatchDropdownEvent('iris.dropdown.change');
		}
	}

	get selectedValueElement(): HTMLElement {
		if (this._selectedValueElement) {
			return this._selectedValueElement;
		}

		utility.toArray(this.element.querySelectorAll('.iris-dropdown__selected-value')).forEach((dupeElement) => dupeElement.remove());

		const selectedValueElement = document.createElement('DIV') as HTMLElement;
		selectedValueElement.classList.add('iris-dropdown__selected-value');
		selectedValueElement.id = utility.generateUniqueId('iris_dropdown__selected_value');
		selectedValueElement.append(this.placeholderElement);
		this._selectedValueElement = selectedValueElement;

		return this._selectedValueElement;
	}

	get validity(): boolean {
		const bool = this.element.classList.contains('iris-dropdown--error') || this.captureInputElement.getAttribute('aria-invalid') === 'true';

		return bool;
	}

	set validity(bool: boolean) {
		this.element.classList.toggle('iris-dropdown--error', bool);
		this.captureInputElement.setAttribute('aria-invalid', bool.toString());
	}

	get value(): string | string[] {
		const options = this.selectedListOptions.map((listOption) => listOption.value);
		if (this.multiselect) {
			return options;
		} else {
			return options[0] || '';
		}
	}

	set value(value: string | string[]) {
		value = value || '';
		const values = typeof value === 'string' ? [value] : value;
		const selectedFn = (listOption: ListOption) => {
			return values.findIndex((val) => val === listOption.value) > -1;
		};
		let selectedListOptions: ListOption[] = [];

		if (this.multiselect) {
			selectedListOptions = this._listOptions.filter(selectedFn);
		} else {
			const selectedOption = this._listOptions.find(selectedFn);
			if (selectedOption) {
				selectedListOptions = [selectedOption];
			}
		}

		this.selectedListOptions = selectedListOptions;
	}

	private get _hiddenInputElements(): HTMLInputElement[] {
		return utility.toArray(this.element.querySelectorAll('.iris-dropdown__input'));
	}

	private get _labelElement(): HTMLElement {
		const labelElement = document.getElementById(this.element.getAttribute('aria-labelledby')) || document.querySelector(`label[for='${this.element.id}']`) as HTMLElement;

		if (labelElement && !labelElement.id) {
			labelElement.id = utility.generateUniqueId('iris_dropdown_label');
		}

		return labelElement;
	}

	// FUNCTIONS
	// --------------------------------------------------------------------------

	// remove any classes that don't need to be there.
	private _cleanClasses(element: HTMLElement) {
		const classNames = [
			'iris-dropdown--expanding',
			'iris-dropdown--expanded',
			'iris-dropdown--collapsing',
			'iris-dropdown--collapsed'
		];

		element.classList.remove(...classNames);
	}

	public destroy(): void {
		this._mutationObserver.disconnect();

		this.expanded = false;
		this._cleanClasses(this.element);

		this.element.removeEventListener('click', this._clickHandler);
		this.element.removeEventListener('_listitemselected', this._listItemSelectedHandler);
		this.element.removeEventListener('keydown', this._keypressHandler);
		document.removeEventListener('click', this._documentClickHandler);

		dom.ancestorsOfElement(this.element, 'form').forEach((formElement) => formElement.removeEventListener('reset', this._formResetHandler));


		this.displayListContainerElement.remove();
		this.chevronElement.remove();
		this.selectedValueElement.remove();
		this.captureInputElement.remove();
	}

	private _dispatchDropdownEvent(eventName: string) {
		this._dispatchEvent(eventName, {
			component: this,
			value: this.value,
		});
	}

	private _isMidTransition = false;
	private _lastExpandedState: boolean | null = null;
	private _manageExpandTransition(expand: boolean) {

		if (this._isMidTransition) {
			this._lastExpandedState = expand;
			return;
		}

		if (!expand) {
			this.activeListOption = null;
		}

		const dropdownExpandedTransitionSteps: ITransitionOptions[] = [
			{
				addClassString: 'iris-dropdown--expanding',
				childWithTransitionSelector: `#${this.displayListContainerElement.id}`,
				removeClassString: 'iris-dropdown--expanded iris-dropdown--collapsing iris-dropdown--collapsed',
				type: 'immediate',
			},
			{
				afterTransitionEventName: 'iris.dropdown.transition.complete',
				childWithTransitionSelector: `#${this.displayListContainerElement.id}`,
				addClassString: 'iris-dropdown--expanded',
				removeClassString: 'iris-dropdown--expanding iris-dropdown--collapsing iris-dropdown--collapsed',
				type: 'transition',
			},
		];

		const dropdownCollapsedTransitionSteps: ITransitionOptions[] = [
			{
				addClassString: 'iris-dropdown--collapsing',
				childWithTransitionSelector: `#${this.displayListContainerElement.id}`,
				removeClassString: 'iris-dropdown--collapsed iris-dropdown--expanding iris-dropdown--expanded',
				type: 'transition'
			},
			{
				afterTransitionEventName: 'iris.dropdown.transition.complete',
				childWithTransitionSelector: `#${this.displayListContainerElement.id}`,
				addClassString: 'iris-dropdown--collapsed',
				removeClassString: 'iris-dropdown--collapsing iris-dropdown--expanding iris-dropdown--expanded',
				type: 'timeout',
				wait: 200
			},
		];

		const dropdownTransitionSteps = expand ? dropdownExpandedTransitionSteps : dropdownCollapsedTransitionSteps;
		const dropdownTransitionEventName = expand ? 'iris.dropdown.expanded' : 'iris.dropdown.collapsed';

		this._isMidTransition = true;

		// calculate if we need to drop down or drop up.
		const viewPortRect = dom.getViewPortBoundingRect();
		const elementRect = this.element.getBoundingClientRect();

		if (expand) {
			this.element.classList.remove('iris-dropdown--dropup');

			if (this._listMaxHeight && (elementRect.bottom + this._listMaxHeight) > viewPortRect.height) {
				this.element.classList.add('iris-dropdown--dropup');
			}
		}

		transitionRunner(this.element, dropdownTransitionSteps);
		dom.handleOnce(this.element, 'iris.dropdown.transition.complete', () => {
			this._dispatchDropdownEvent(dropdownTransitionEventName);
			this._isMidTransition = false;

			if (this._lastExpandedState !== null) {
				const expandState = this._lastExpandedState;
				this._lastExpandedState = null;
				if (expandState !== expand) {
					this._manageExpandTransition(expandState);
				}
			}
		});
	}

	private _manageHiddenInputs(values: string[]) {

		const vals = values.length === 0 ? [null] : values;
		const hiddenInputElements = this._hiddenInputElements;

		this._hiddenInputElements.forEach((hiddenInputElement, index) => {
			if (index > values.length) {
				hiddenInputElement.remove();
			}
		});

		vals.forEach((value, index) => {
			const id = this.element.id + '_value_' + index;
			const inputElement = (document.getElementById(id) || document.createElement('INPUT')) as HTMLInputElement;

			// hidden fields can't be validated... but display none text fields can!
			inputElement.style.display = 'none';
			inputElement.setAttribute('type', 'text');
			inputElement.setAttribute('name', this.name);
			inputElement.classList.add('iris-dropdown__input');
			inputElement.required = this.required;

			if (!inputElement.id) {
				inputElement.id = id;
			}

			if (value !== null) {
				inputElement.setAttribute('value', value);
			} else {
				inputElement.removeAttribute('value');
			}

			this.element.append(inputElement);
		});
	}

	private _getActiveOptionIndex(): number {
		const options = this.options;
		const currentActiveOption = this.activeListOption;
		const activeOption = currentActiveOption || this.selectedListOptions[0] || null;

		if (!activeOption) {
			return -1;
		}

		const activeOptionIndex = options.findIndex((option) => activeOption.id === option.id);
		return activeOptionIndex;
	}

	private _selectActiveListOption(): void {
		this.activeListOption = this.activeListOption || this.selectedListOptions[0];
		this._selectListOption(this.activeListOption);
	}

	private _selectListOption(option: ListOption): void {

		if (this.disabled) {
			this.expanded = false;
			return;
		}


		if (this.expanded && option && !option.disabled) {
			let selectedListOptions = this.selectedListOptions;

			if (this.multiselect) {
				const listOptionIndex = selectedListOptions.findIndex((selectedListOption) => selectedListOption.id === option.id);
				if (listOptionIndex >= 0) {
					selectedListOptions = selectedListOptions.filter((selectedListOption) => selectedListOption.id !== option.id);
				} else {
					selectedListOptions.push(option);
				}
			} else {
				selectedListOptions = [option];
			}

			this.expanded = this.multiselect;
			this.selectedListOptions = selectedListOptions;

			if (this.multiselect) {
				this._statusRegion.broadcast(RegionSettings.format(option.selected ? 'dropdown.sr.selected' : 'dropdown.sr.unselected', option.text));
			}
		} else {
			this.expanded = !this.expanded;
			if (option && this.expanded) {
				dom.scrollIntoViewIfNeeded(option.displayListItem.element, true);
			}
		}
	}

	private _updateCaptureInputElement(): void {
		const selectedOptionText = this.selectedListOptions.length === 1 ? this.selectedListOptions[0].text : RegionSettings.format('dropdown.multiselect.selected', this.selectedListOptions.length.toString());
		if (this._labelElement) {
			this.captureInputElement.setAttribute('aria-labelledby', `${this._labelElement.id} ${this._captureInputElement.id}`);
		}

		this.captureInputElement.innerText = selectedOptionText;
	}

	private _updateComponentState = () => {
		const oldListOptions = this._listOptions;

		// Update Component Data
		this._updateListOptions();
		this._manageHiddenInputs(this.selectedListOptions.map(((listOption) => listOption.value)));
		this._updateSelectedValueElement();
		this._updateCaptureInputElement();
		this._updateListHeader();

		if (this.element.getAttribute('data-populated') === 'all' || !utility.areArraysEqual(oldListOptions, this._listOptions)) {
			dom.dispatchEvent(this.element, 'iris.dropdown.populated', {
				component: this,
				listOptions: this._listOptions,
			});
		}
	}

	private _updateListHeader(): void {
		if (this._labelElement) {
			this.displayListContainerTitle.innerText = this._labelElement.innerText;
		}
	}

	private _updateListOptions(): void {
		let listOptionsConfig: IListOptionConfig = null;
		if (this.element.dataset.optionAttributeBlacklist) {
			listOptionsConfig = {
				blackListAttributes: utility.splitAttributeToList(this.element.dataset.optionAttributeBlacklist)
			};
		}

		const dataListItemElements = utility.toArray(this.dataListElement.querySelectorAll('.iris-option'));

		this._listOptions = dataListItemElements.map((element) => {
			const listOption = this._listOptions.find((oldItem) => oldItem.id === element.id) || new ListOption(element, listOptionsConfig);

			return listOption;
		});

		const displayListItemElements = this._listOptions.map((listOption) => {
			listOption.updateListItem();
			return listOption.displayListItem.element;
		});

		// Because of IE we need to use the removeChildNodes since innerHTML = '' will clear out all child node's innerHTML as well. Thanks IE11!
		dom.removeChildNodes(this.displayListElement);

		if (displayListItemElements.length > 0) {
			this.displayListElement.append(...displayListItemElements);
		}
	}

	private _updateSelectedValueElement(): void {
		const selectedListOptions = this.selectedListOptions;
		const placeholderElement = this.placeholderElement;

		if (this.element.hasAttribute('placeholder')) {
			placeholderElement.innerText = this.element.getAttribute('placeholder');
		} else {
			placeholderElement.innerHTML = '&nbsp;';
		}

		dom.removeChildNodes(this._selectedValueElement);

		if (selectedListOptions.length === 0) {
			this._selectedValueElement.append(placeholderElement);
		}

		if (selectedListOptions.length === 1) {
			const option = selectedListOptions[0];
			this._selectedValueElement.innerHTML = option.selectedViewElement.innerHTML;
			this.captureInputElement.setAttribute('aria-describedby', this._selectedValueElement.id);
		} else if (selectedListOptions.length > 1) {
			this._selectedValueElement.innerText = RegionSettings.format('dropdown.multiselect.selected', selectedListOptions.length.toString());
			this.captureInputElement.setAttribute('aria-describedby', this._selectedValueElement.id);
		}
	}

	// Event Handlers
	// --------------------------------------------------------------------------

	private _clickHandler = (event: Event) => {
		if (this.disabled) {
			return;
		}

		const target = event.target as HTMLElement;

		if (target === this.displayListContainerHeader || target === this.displayListContainerTitle) {
			return;
		}

		this.expanded = !this.expanded;
	}

	private _documentClickHandler = (event: Event) => {
		const target = event.target as HTMLElement;
		const targetIsChild = !!dom.ancestorsOfElement(target).find((element) => element === this.element);

		if (targetIsChild) {
			return;
		}

		this.expanded = false;
	}

	private _formResetHandler = () => {
		this.selectedListOptions = [];
	}

	private _keypressHandler = (event: Event) => { this._createKeypressEventListener()(event); };

	private _listItemSelectedHandler = (event: CustomEvent) => {
		const listItem = event.detail.component as ListItem;
		const listOptionElement = document.getElementById(listItem.element.dataset.optionId);
		const selectedListOption = this._listOptions.find((listOption) => listOption.element.id === listOptionElement.id);

		this._selectListOption(selectedListOption);
	}

	private _mutationObserverHandler = (records: MutationRecord[]) => {

		records = records.filter((record) => {
			if (record.target === this.element) {
				return record.attributeName !== 'class';
			}

			return true;
		});

		if (records.length === 0) {
			return;
		}

		this._updateComponentState();

	}

	private _createKeypressEventListener(): EventListener {
		let word = '';

		const resetWord = () => { word = ''; };
		const searchOptions = utility.debounce((_word: string) => {
			const activeOption = this.options.find((option) => {
				const optionText = option.element.innerText.trim().toLowerCase();
				return optionText.indexOf(_word) === 0;
			});

			if (activeOption) {
				this.activeListOption = activeOption;
			}
			resetWord();

		}, 250);

		const moveActiveOptionIndex = (steps: number): number => {
			const activeOptionIndex = this._getActiveOptionIndex();
			const options = this.options;
			const minIndex = 0;
			const maxIndex = options.length - 1;
			let movedIndex = activeOptionIndex + steps;

			if (movedIndex < minIndex) {
				movedIndex = 0;
			}

			if (movedIndex > maxIndex) {
				movedIndex = maxIndex;
			}

			return movedIndex;
		};

		const moveActiveOption = (step: number) => {
			if (!this.expanded) {
				this._selectActiveListOption();
			}

			const options = this.options;
			this.activeListOption = options[moveActiveOptionIndex(step)];

			return false;
		};

		const selectActiveListOption = () => {
			this._selectActiveListOption();
			return false;
		};

		return createKeypressEventListener({
			up: () => moveActiveOption(-1),
			down: () => moveActiveOption(1),
			spacebar: selectActiveListOption,
			enter: selectActiveListOption,
			tab: () => {
				this.expanded = false;
				return true;
			},
			escape: () => {
				this.expanded = false;
			},
			default: (e: KeyboardEvent) => {
				const char = e.key;

				if (char.length === 1 && char.match(/[a-z]/i)) {
					word = word + char;
					this.expanded = true;
					searchOptions(word);
				}

				return true;
			},
		} as IKeyPressHandlers) as EventListener;
	}

	// STATIC
	// --------------------------------------------------------------------------

	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '.iris-dropdown',
		componentName: 'DropdownComponent'
	};

	private static _defaultComponentOptions: IDropdownComponentOptions = {
		idPrefix: 'iris_dropdown',
	};

	public static factory = new GenericComponentFactory<DropdownComponent, IDropdownComponentOptions>(DropdownComponent, DropdownComponent._factoryOptions, DropdownComponent._defaultComponentOptions);
}

import { createKeypressEventListener } from '../keypress-handler';
import { generateUniqueId, toArray } from '../utility';

// TODO: This is just importing an interface. We should add the interface to a d.ts file.
import { Component, IComponentOptions } from '../component';
import { FocusManager } from '../focus-manager';
import { IIrisLogger } from '../logger';
import { SyntheticButtonComponent } from './synthetic-button';


export interface IModalOptions extends IComponentOptions {
	afterCloseEvent: string;
	afterOpenEvent: string;
	beforeCloseEvent: string;
	beforeOpenEvent: string;
	optionalOpenEvent: string;
	optionalCloseEvent: string;
	type: string;
}

export abstract class Modal extends Component {
	protected _logger: IIrisLogger;
	protected _open: boolean = false;
	protected _freeze: boolean = false;
	protected _isMidTransition: boolean = false;
	protected _backdropElement: HTMLElement;
	protected _triggerOpenElements: HTMLElement[];
	protected _triggerCloseElements: HTMLElement[];
	protected _modalTitleElement: HTMLElement;
	protected _focusManager: FocusManager;

	protected get _options(): IModalOptions {
		return this.getOptions() as IModalOptions;
	}

	protected constructor(element: HTMLElement, options: IModalOptions) {
		super(element, options);
		this._logger = {
			component: this._options.type,
			message: '',
			type: 'trace',
		};

		this._backdropElement = document.createElement('DIV');
		this._backdropElement.classList.add('iris-backdrop');

		this._focusManager = new FocusManager(this.element, [
			[
				'[data-autofocus]',
			],
			[
				'input:not([type=hidden])',
				'select',
				'.iris-dropdown__capture-input',
				`.iris-${this._options.type}__body button`,
			],
			[
				`.iris-${this._options.type}__footer .iris-button--primary`,
			],
			[
				`.iris-${this._options.type}__footer .iris-button--secondary`,
			],
		]);

		// Check for an element with a data-[TYPE] value with an ID matching the element's.
		// This will be the element to trigger the modal. If there is no ID on the modal,
		// we assume this will be done programatically by some black magic elsewhere.
		this._triggerOpenElements = toArray(document.querySelectorAll(`[data-${this._options.type}]`))
			.filter((item: HTMLElement) => item.dataset[this._options.type] === this.element.id);

		// Also search for any descendants of the modal that have a data-close attribute.
		// These items will need to initiate the modal close action.
		this._triggerCloseElements = toArray(this.element.querySelectorAll('[data-close]'));

		this._modalTitleElement = this.element.querySelector(`.iris-${this._options.type}__title`);

		this._addAccessibility();
		this._createEventListeners();
	}

	public destroy() {
		this.open = false;

		this._removeEventListeners();
		this._backdropElement.remove();
	}

	public get freeze(): boolean {
		return this._freeze;
	}

	public set freeze(value) {
		if (this._freeze === value) {
			return;
		}

		if (value) {
			this._removeEventListeners();
		} else {
			this._createEventListeners();
		}

		this._freeze = value;
	}

	public get backdropElement() {
		return this._backdropElement;
	}


	// ABSTRACT METHODS
	// =============================================================================
	abstract get open(): boolean;
	abstract set open(value: boolean);


	// FOCUS METHODS
	// =============================================================================
	protected _setFocus() {
		this.element.setAttribute('aria-hidden', 'false');
		this._focusManager.setFocus();
	}

	protected _returnFocus() {
		this.element.setAttribute('aria-hidden', 'true');
		this._focusManager.returnFocus();
	}


	// EVENT METHODS
	// =============================================================================
	private _createEventListeners() {
		// Set up keybinding on Escape to close the modal.
		document.addEventListener('keydown', this._keypressHandler);

		// Set up click on the backdrop to close the modal.
		this._backdropElement.addEventListener('click', this._escapeHandler);

		this._triggerOpenElements.concat(this._triggerCloseElements).map((element: HTMLElement) => {
			element.addEventListener('click', this._sourceElementClickHandler);

			if (element.tagName !== 'BUTTON') {
				SyntheticButtonComponent.factory.init(element);
			}

			return element;
		});
	}

	private _removeEventListeners() {
		document.removeEventListener('keydown', this._keypressHandler);
		this._backdropElement.removeEventListener('click', this._escapeHandler);

		// Remove the trigger elements set to open and close the modal.
		this._triggerOpenElements.concat(this._triggerCloseElements).map((element) => {
			element.removeEventListener('click', this._sourceElementClickHandler);

			if (element.tagName !== 'BUTTON') {
				SyntheticButtonComponent.factory.destroy(element);
			}

			return element;
		});
	}

	private _escapeHandler = () => {
		// Make sure the modal is open before setting the open attribute.
		if (this._open === true) {
			this.open = false;
		}
	}

	private _keypressHandler = createKeypressEventListener({
		escape: (event: KeyboardEvent) => {
			this._escapeHandler();
			return false;
		},
	});

	private _sourceElementClickHandler = () => {
		this.open = !this._open;
	}

	// ACCESSIBILITY
	// =============================================================================
	private _addAccessibility() {
		// Display none
		const isHidden = this.element.classList.contains('hidden');

		if (!isHidden) {
			this.element.classList.add('hidden');
		}

		// Role
		const contentElement = this.element.querySelector(`.iris-${this._options.type}__content`);
		const modalCloseElement = this.element.querySelector(`.iris-${this._options.type}__header [data-close]`);

		if (this.element.getAttribute('role') !== 'dialog') {
			this.element.setAttribute('role', 'dialog');
		}

		// Make sure to check that the content section exists before getting the attribute.
		if (contentElement && contentElement.getAttribute('role') !== 'document') {
			contentElement.setAttribute('role', 'document');
		}

		// The close button may or may not be in the modal. Bail out if it doesn't exist.
		if (modalCloseElement && !modalCloseElement.getAttribute('aria-label')) {
			modalCloseElement.setAttribute('aria-label', `Close ${this._options.type}`);
		}

		// Tabindex
		if (this.element.getAttribute('tabindex') !== '-1') {
			this.element.setAttribute('tabindex', '-1');
		}

		if (this._modalTitleElement) {
			this._modalTitleElement.setAttribute('tabindex', '-1');
		}

		// Aria-labelledby
		if (!this.element.hasAttribute('aria-labelledby')) {
			const title = this.element.querySelector(`.iris-${this._options.type}__title`);

			if (title) {
				if (!title.id) {
					title.id = generateUniqueId(`${this._options.type}_title`);
				}

				this.element.setAttribute('aria-labelledby', title.id);
			}
		}

		// Aria-hidden
		if (this.element.getAttribute('aria-hidden') !== 'true') {
			this.element.setAttribute('aria-hidden', 'true');
		}
	}
}

import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import { getScrollbarWidth, hasScrollbar } from '../dom';
import { ITransitionOptions, transitionRunner } from '../transition-handler';
import { dispatchIrisEvent } from '../utility';
import { IModalOptions, Modal } from './modal';

export class DrawerComponent extends Modal {
	constructor(element: HTMLElement, options: IModalOptions) {
		super(element, options);
	}

	get open() {
		return this._open;
	}

	set open(value: boolean) {
		// If the value passed in is the same or is mid transition, shortcircut to prevent running the animation logic.
		if (this._open === value || this._isMidTransition === true) {
			return;
		}

		// Prevent any further changes to the open state until the transition is complete.
		this._isMidTransition = true;
		this._open = value;

		if (value) {
			this._openEventAndAction();
			document.body.addEventListener('focusin', this._focusLoopHandler);
		} else {
			this._closeEventAndAction();
			document.body.removeEventListener('focusin', this._focusLoopHandler);
		}
	}

	private _focusLoopHandler = (event: FocusEvent) => {
		if (!this.element.contains(event.target as HTMLElement)) {
			this.element.focus();
		}
	}

	private _openEventAndAction() {
		// DISPATCH: Before open event
		dispatchIrisEvent(this.element, this._options.beforeOpenEvent, this._logger);

		// ACTION: Open the drawer and set focus
		this._openAction();
		this._setFocus();
	}

	private _closeEventAndAction() {
		// DISPATCH: Before close event
		dispatchIrisEvent(this.element, this._options.beforeCloseEvent, this._logger);

		// ACTION: Close the drawer and return focus
		this._closeAction();
		this._returnFocus();
	}

	private _openAction() {
		document.body.prepend(this.backdropElement);

		const scrollbarWidth = hasScrollbar() ? getScrollbarWidth() : 0;
		document.body.style.borderRight = `${scrollbarWidth}px solid transparent`;

		// Remove the hidden class on the drawer and then open it!
		const drawerTransitionSteps: ITransitionOptions[] = [
			{
				removeClassString: 'hidden',
				type: 'immediate',
			},
			{
				afterTransitionEventName: 'iris.drawer.transition.complete',
				addClassString: 'iris-drawer--open',
				type: 'timeout',
				wait: 50,
			},
		];

		// Add the open class to the body for the backdrop after a small wait
		const backdropTransitionSteps: ITransitionOptions[] = [
			{
				addClassString: 'iris-backdrop--light',
				type: 'immediate',
			},
			{
				addClassString: 'iris-backdrop--open',
				type: 'timeout',
				wait: 50,
			},
		];

		const afterDrawerOpenHandler = () => {
			this.element.removeEventListener('iris.drawer.transition.complete', afterDrawerOpenHandler);
			this._isMidTransition = false;
			document.body.classList.add('no-scroll');

			// DISPATCH: After open event
			dispatchIrisEvent(this.element, this._options.afterOpenEvent, this._logger);
		};

		this.element.addEventListener('iris.drawer.transition.complete', afterDrawerOpenHandler);

		transitionRunner(this.element, drawerTransitionSteps);
		transitionRunner(this.backdropElement, backdropTransitionSteps);
	}

	private _closeAction() {
		// Remove the hidden class on the drawer and then open it!
		const drawerTransitionSteps: ITransitionOptions[] = [
            {
				addClassString: 'iris-drawer--closing',
				type: 'immediate',
			},
			{
				removeClassString: 'iris-drawer--open',
				type: 'transition',
			},
			{
				afterTransitionEventName: 'iris.drawer.transition.complete',
				addClassString: 'hidden',
				removeClassString: 'iris-drawer--closing',
				type: 'immediate',
			},
		];

		// Add the open class to the body for the backdrop after a small wait
		const backdropTransitionSteps: ITransitionOptions[] = [
			{
				removeClassString: 'iris-backdrop--open',
				type: 'immediate',
			},
			{
				afterTransitionEventName: 'iris.backdrop.transition.complete',
				removeClassString: 'iris-backdrop--light',
				type: 'timeout',
				wait: 400,
			},
		];

		const afterDrawerCloseHandler = () => {
			this.element.removeEventListener('iris.drawer.transition.complete', afterDrawerCloseHandler);
			this._isMidTransition = false;
			document.body.classList.remove('no-scroll');

			// DISPATCH: After close event
			dispatchIrisEvent(this.element, this._options.afterCloseEvent, this._logger);
		};

		const afterBackdropFadeHandler = () => {
			this.backdropElement.removeEventListener('iris.backdrop.transition.complete', afterBackdropFadeHandler);
			document.body.style.borderRight = null;
			this.backdropElement.remove();
		};

		this.element.addEventListener('iris.drawer.transition.complete', afterDrawerCloseHandler);
		this.backdropElement.addEventListener('iris.backdrop.transition.complete', afterBackdropFadeHandler);

		transitionRunner(this.element, drawerTransitionSteps);
		transitionRunner(this.backdropElement, backdropTransitionSteps);
	}

	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '.iris-drawer',
		componentName: 'DrawerComponent'
	};

	private static _defaultComponentOptions: IModalOptions = {
		afterCloseEvent: 'iris.drawer.closed',
		afterOpenEvent: 'iris.drawer.opened',
		beforeCloseEvent: 'iris.drawer.closing',
		beforeOpenEvent: 'iris.drawer.opening',
		optionalOpenEvent: '',
		optionalCloseEvent: '',
		type: 'drawer',
		idPrefix: 'iris_drawer',
	};

	public static factory = new GenericComponentFactory<DrawerComponent, IModalOptions>(DrawerComponent, DrawerComponent._factoryOptions, DrawerComponent._defaultComponentOptions);
}

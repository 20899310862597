import * as dom from './dom';
import { IIrisLogger, IrisLoggerLevel, logger } from './logger';
import { generateUniqueId, toArray } from './utility';
export abstract class Component {
	public irisId: string;
	private __element: HTMLElement;
	private __options: IComponentOptions;
	private __messageObject: IIrisLogger;

	constructor(element: HTMLElement, options?: IComponentOptions) {
		this.__element = element;
		this.__options = options;
		this.__messageObject = {
			component: options.instanceName || this.getOptions().componentName,
			message: '',
			type: 'trace'
		};

		if (!this.__element.id) {
			this.__element.id = generateUniqueId(options.idPrefix || 'iris_component');
		}
	}

	public abstract destroy(): void;

	public get element() {
		return this.__element;
	}

	public getOptions() { return this.__options; }

	protected _dispatchEvent(eventName: string, detail: any = this.__element, element: HTMLElement = this.__element, messageObject: IIrisLogger = this.__messageObject, bubbles: boolean = true, cancelable: boolean = true) {
		dom.dispatchEvent(element, eventName, detail, bubbles, cancelable);

		this._log(`Dispatching event ${eventName} ${element.id ? '#' + element.id : ''}`);
	}

	protected _log(message: string, type: IrisLoggerLevel = 'trace', tip?: string): void {
		logger(Object.assign({}, this.__messageObject, { message, tip, type }));
	}
}

export interface IComponentOptions {
	idPrefix?: string;
	instanceName?: string;
	componentName?: string;
}

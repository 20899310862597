import * as utility from '../utility';
import { dispatchIrisEvent, generateUniqueId } from '../utility';
import { IModalOptions, Modal } from './modal';

// TODO: This is just importing an interface. We should add the interface to a d.ts file.
import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import { getScrollbarWidth, hasScrollbar } from '../dom';
import { ITransitionOptions, transitionRunner } from '../transition-handler';

const narrowVariantDeprecationWarning = utility.once(function() {
	// tslint:disable-next-line no-console
	console.warn('Using "narrow" for selecting a variant size has been deprecated. Please use "small" instead.');
});

const wideVariantDeprecationWarning = utility.once(function() {
	// tslint:disable-next-line no-console
	console.warn('Using "wide" for selecting a variant size has been deprecated. Please use "medium" instead.');
});

export class PromptComponent extends Modal {
	constructor(element: HTMLElement, options: IModalOptions) {
		super(element, options);

		element.setAttribute('role', 'alertdialog');

		if (!this.element.hasAttribute('aria-describedby')) {
			const body = element.querySelector('.iris-prompt__body');

			if (body) {
				body.id = body.id || generateUniqueId('prompt_body');
				element.setAttribute('aria-describedby', body.id);
			}
		}

		if (element.dataset.size === 'narrow') {
			narrowVariantDeprecationWarning();
		}

		if (element.dataset.size === 'wide') {
			wideVariantDeprecationWarning();
		}
	}

	get open() {
		return this._open;
	}

	set open(value: boolean) {
		// If the value passed in is the same or is mid-transition, shortcircut to prevent running the animation logic.
		if (this._open === value || this._isMidTransition === true) {
			return;
		}

		// Prevent any further changes to the open state until the transition is complete.
		this._isMidTransition = true;
		this._open = value;

		if (value) {
			this._openEventAndAction();
			document.body.addEventListener('focusin', this._focusLoopHandler);
		} else {
			document.body.removeEventListener('focusin', this._focusLoopHandler);
			this._closeEventAndAction();
		}
	}

	private _focusLoopHandler = (event: FocusEvent) => {
		if (!this.element.contains(event.target as HTMLElement)) {
			this.element.focus();
		}
	}

	private _openEventAndAction() {
		// DISPATCH: Before open event
		dispatchIrisEvent(this.element, this._options.beforeOpenEvent, this._logger);

		// ACTION: Open the prompt and return focus
		this._openAction();
		this._setFocus();
	}

	private _closeEventAndAction() {
		// DISPATCH: Before close event
		dispatchIrisEvent(this.element, this._options.beforeCloseEvent, this._logger);

		// ACTION: Close the prompt and return focus
		this._closeAction();
		this._returnFocus();
	}

	private _openAction() {
		document.body.prepend(this.backdropElement);

		// Add classes to the prompt and the body to deal with the display items
		// like removing scrolling from the document, animating the prompt, etc.
		const scrollbarWidth = hasScrollbar() ? getScrollbarWidth() : 0;
		document.body.style.borderRight = `${scrollbarWidth}px solid transparent`;

		// Remove the hidden class on the drawer and then open it!
		const drawerTransitionSteps: ITransitionOptions[] = [
			{
				removeClassString: 'hidden',
				type: 'immediate',
			},
			{
				afterTransitionEventName: 'iris.prompt.transition.complete',
				addClassString: 'iris-prompt--open',
				type: 'timeout',
				wait: 50,
			},
		];

		// Add the open class to the body for the backdrop after a small wait
		const backdropTransitionSteps: ITransitionOptions[] = [
			{
				addClassString: 'iris-backdrop--dark',
				type: 'immediate',
			},
			{
				addClassString: 'iris-backdrop--open',
				type: 'timeout',
				wait: 50,
			},
		];

		const afterPromptOpenHandler = () => {
			this.element.removeEventListener('iris.prompt.transition.complete', afterPromptOpenHandler);
			this._isMidTransition = false;
			document.body.classList.add('no-scroll');

			// DISPATCH: After open event
			dispatchIrisEvent(this.element, this._options.afterOpenEvent, this._logger);
		};

		this.element.addEventListener('iris.prompt.transition.complete', afterPromptOpenHandler);

		transitionRunner(this.element, drawerTransitionSteps);
		transitionRunner(this.backdropElement, backdropTransitionSteps);
	}

	private _closeAction() {
		// Remove the hidden class on the prompt and then open it!
		const promptTransitionSteps: ITransitionOptions[] = [
			{
				addClassString: 'iris-prompt--closing',
				type: 'immediate',
			},
			{
				removeClassString: 'iris-prompt--open',
				type: 'transition',
			},
			{
				afterTransitionEventName: 'iris.prompt.transition.complete',
				addClassString: 'hidden',
				removeClassString: 'iris-prompt--closing',
				type: 'immediate',
			},
		];

		// Add the open class to the body for the backdrop after a small wait
		const backdropTransitionSteps: ITransitionOptions[] = [
			{
				removeClassString: 'iris-backdrop--open',
				type: 'immediate',
			},
			{
				afterTransitionEventName: 'iris.backdrop.transition.complete',
				removeClassString: 'iris-backdrop--dark',
				type: 'timeout',
				wait: 400,
			},
		];

		const afterPromptCloseHandler = () => {
			this.element.removeEventListener('iris.prompt.transition.complete', afterPromptCloseHandler);
			this._isMidTransition = false;
			document.body.classList.remove('no-scroll');

			// DISPATCH: After close event
			dispatchIrisEvent(this.element, this._options.afterCloseEvent, this._logger);
		};

		const afterBackdropFadeHandler = () => {
			this.backdropElement.removeEventListener('iris.backdrop.transition.complete', afterBackdropFadeHandler);
			this.backdropElement.remove();
			document.body.style.borderRight = null;
		};

		this.element.addEventListener('iris.prompt.transition.complete', afterPromptCloseHandler);
		this.backdropElement.addEventListener('iris.backdrop.transition.complete', afterBackdropFadeHandler);

		transitionRunner(this.element, promptTransitionSteps);
		transitionRunner(this.backdropElement, backdropTransitionSteps);
	}

	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '.iris-prompt',
		componentName: 'PromptComponent'
	};

	private static _defaultComponentOptions: IModalOptions = {
		afterCloseEvent: 'iris.prompt.closed',
		afterOpenEvent: 'iris.prompt.opened',
		beforeCloseEvent: 'iris.prompt.closing',
		beforeOpenEvent: 'iris.prompt.opening',
		optionalOpenEvent: '',
		optionalCloseEvent: '',
		type: 'prompt',
	};

	public static factory = new GenericComponentFactory<PromptComponent, IModalOptions>(PromptComponent, PromptComponent._factoryOptions, PromptComponent._defaultComponentOptions);

}

import { CalendarComponent } from './components/calendar';
import { RegionSettings } from './regional';

export class FormattedDate {
	public date: Date;

	constructor(date: Date) {
		this.date = date;
	}

	get dayOfMonth(): string {
		return this.date.getDate().toString(10).padStart(2, '0');
	}

	get abbreviatedDay(): string {
		return daysOfTheWeek[this.date.getDay()];
	}

	get abbreviatedMonth(): string {
		return findAbbrMonthName()[this.date.getMonth()];
	}

	get year(): string {
		return this.date.getFullYear().toString(10);
	}

	get fullDate(): string {
		return `${monthNames[this.date.getMonth()]} ${this.dayOfMonth}, ${this.year}`;
	}
}


// Date Compare functions
// =============================================================================
export function isSameDay(d1: Date | string, d2: Date | string): boolean {
	const firstDate = typeof d1 === 'string' ? new Date(d1) : d1;
	const secondDate = typeof d2 === 'string' ? new Date(d2) : d2;

	return firstDate.getUTCFullYear() === secondDate.getUTCFullYear() &&
		firstDate.getUTCMonth() === secondDate.getUTCMonth() &&
		firstDate.getUTCDate() === secondDate.getUTCDate();
}

export function convert(inputDate: any) {
	// Converts the date in d to a date-object. The input can be:
	//   a date object: returned without modification
	//   an array     : Interpreted as [year,month,day]. NOTE: month is 0-11.
	//   a number     : Interpreted as number of milliseconds since 1 Jan 1970 (a timestamp)
	//   a string     : Any format supported by the javascript engine, like "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
	//   an object    : Interpreted as an object with year, month and date attributes.  **NOTE** month is 0-11.
	if (inputDate.constructor === Date) {
		return inputDate as Date;
	} else if (inputDate.constructor === Array) {
		return new Date(inputDate[0], inputDate[1], inputDate[2]);
	} else if (inputDate.constructor === Number) {
		return new Date(inputDate as number);
	} else if (inputDate.constructor === String) {
		return new Date(inputDate as string);
	} else if (typeof inputDate === 'object') {
		return new Date(inputDate.year, inputDate.month, inputDate.date);
	} else {
		return NaN;
	}
}

export function compare(a: any, b: any) {
	// Compare two dates (could be of any type supported by the convert
	// function above) and returns:
	//  -1 : if a < b
	//   0 : if a = b
	//   1 : if a > b
	// NaN : if a or b is an illegal date
	const firstDate: number = convert(a).valueOf();
	const secondDate: number = convert(b).valueOf();

	if (isFinite(firstDate) && isFinite(secondDate)) {
		// Number(boolean > boolean) coerces a boolean into a number
		// true = 1, false = 0
		return (Number(firstDate > secondDate)) - (Number(firstDate < secondDate));
	}

	return NaN;
}

export function inRange(d: any, start: any, end: any) {
	// Checks if date in d is between dates in start and end.
	// Returns a boolean or NaN:
	//    true  : if d is between start and end (inclusive)
	//    false : if d is before start or after end
	//    NaN   : if one or more of the dates is illegal.
	const dateToCompare = convert(d).valueOf();
	const startDate = convert(start).valueOf();
	const endDate = convert(end).valueOf();

	if (isFinite(dateToCompare) && isFinite(startDate) && isFinite(endDate)) {
		return (start <= d && d <= end);
	}

	return NaN;
}


// Day, month, and year label arrays
// =============================================================================

// functions are in use for compatibility with regional language changes

export function findDayOfTheWeek() {
	return [
		RegionSettings.format('calendar.dayofweekabbr.sunday'),
		RegionSettings.format('calendar.dayofweekabbr.monday'),
		RegionSettings.format('calendar.dayofweekabbr.tuesday'),
		RegionSettings.format('calendar.dayofweekabbr.wednesday'),
		RegionSettings.format('calendar.dayofweekabbr.thursday'),
		RegionSettings.format('calendar.dayofweekabbr.friday'),
		RegionSettings.format('calendar.dayofweekabbr.saturday')
	];
};

export function findMonthName(month: number) {
	const monthArr = [
		RegionSettings.format('calendar.month.january'),
		RegionSettings.format('calendar.month.february'),
		RegionSettings.format('calendar.month.march'),
		RegionSettings.format('calendar.month.april'),
		RegionSettings.format('calendar.month.may'),
		RegionSettings.format('calendar.month.june'),
		RegionSettings.format('calendar.month.july'),
		RegionSettings.format('calendar.month.august'),
		RegionSettings.format('calendar.month.september'),
		RegionSettings.format('calendar.month.october'),
		RegionSettings.format('calendar.month.november'),
		RegionSettings.format('calendar.month.december')
	];

	return monthArr[month];
};

export function findAbbrMonthName() {
	return [
		RegionSettings.format('calendar.monthabbr.january'),
		RegionSettings.format('calendar.monthabbr.february'),
		RegionSettings.format('calendar.monthabbr.march'),
		RegionSettings.format('calendar.monthabbr.april'),
		RegionSettings.format('calendar.monthabbr.may'),
		RegionSettings.format('calendar.monthabbr.june'),
		RegionSettings.format('calendar.monthabbr.july'),
		RegionSettings.format('calendar.monthabbr.august'),
		RegionSettings.format('calendar.monthabbr.september'),
		RegionSettings.format('calendar.monthabbr.october'),
		RegionSettings.format('calendar.monthabbr.november'),
		RegionSettings.format('calendar.monthabbr.december')
	];
};

// consts are not currently in use by calandar.ts and exist to support legacy code

export const daysOfTheWeek = [
	RegionSettings.format('calendar.dayofweekabbr.sunday'),
	RegionSettings.format('calendar.dayofweekabbr.monday'),
	RegionSettings.format('calendar.dayofweekabbr.tuesday'),
	RegionSettings.format('calendar.dayofweekabbr.wednesday'),
	RegionSettings.format('calendar.dayofweekabbr.thursday'),
	RegionSettings.format('calendar.dayofweekabbr.friday'),
	RegionSettings.format('calendar.dayofweekabbr.saturday')
];

export const monthNames = [
	RegionSettings.format('calendar.month.january'),
	RegionSettings.format('calendar.month.february'),
	RegionSettings.format('calendar.month.march'),
	RegionSettings.format('calendar.month.april'),
	RegionSettings.format('calendar.month.may'),
	RegionSettings.format('calendar.month.june'),
	RegionSettings.format('calendar.month.july'),
	RegionSettings.format('calendar.month.august'),
	RegionSettings.format('calendar.month.september'),
	RegionSettings.format('calendar.month.october'),
	RegionSettings.format('calendar.month.november'),
	RegionSettings.format('calendar.month.december')
];

export const abbreviatedMonthNames = [
	RegionSettings.format('calendar.monthabbr.january'),
	RegionSettings.format('calendar.monthabbr.february'),
	RegionSettings.format('calendar.monthabbr.march'),
	RegionSettings.format('calendar.monthabbr.april'),
	RegionSettings.format('calendar.monthabbr.may'),
	RegionSettings.format('calendar.monthabbr.june'),
	RegionSettings.format('calendar.monthabbr.july'),
	RegionSettings.format('calendar.monthabbr.august'),
	RegionSettings.format('calendar.monthabbr.september'),
	RegionSettings.format('calendar.monthabbr.october'),
	RegionSettings.format('calendar.monthabbr.november'),
	RegionSettings.format('calendar.monthabbr.december')
];

import { Component, IComponentOptions } from '../component';
import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import * as dom from '../dom';
import { createKeypressEventListener, IKeyPressHandlers } from '../keypress-handler';
import { RegionSettings } from '../regional';
import * as utility from '../utility';
import { ListItem } from './list-item';
import { LiveRegion } from './live-region';

// TODO: ENHANCEMENT: Add the ability to put values into hidden inputs for form submittion

export interface IListComponentOptions extends IComponentOptions {
	componentClass?: string;
	listItemClass?: string;
}
export type VariantType = 'static' | 'actionable' | 'checkable' | 'navigable';

const VARIANTS = {
	static: 'static',
	actionable: 'actionable',
	checkable: 'checkable',
	navigable: 'navigable',
};

export class ListComponent extends Component {

	private _listItems: ListItem[] = [];
	private _statusRegion: LiveRegion = LiveRegion.globalStatusRegion;
	private activeListItemIndex: number = -1;

	private _mutationObserver: MutationObserver = new MutationObserver((info) => {
		this._updateListRole();
		this._updateListItems();
	});

	constructor(element: HTMLElement, options: IListComponentOptions = {}) {
		super(element, options);

		this._updateListItems();
		this._updateListRole();

		this.element.addEventListener('_listitemselected', this._listItemSelectedHandler);
		this.element.addEventListener('keydown', this._keypressHandler);
		this.element.addEventListener('focusin', this._focusinHandler);
		this._mutationObserver.observe(this.element, { attributes: true, childList: true, subtree: true });
	}

	get activeListItem(): ListItem {
		// If aria-activedescendant is not an attribute on the active element, activeDescendantId will be null
		const activeDescendantId = this.element.getAttribute('aria-activedescendant');
		return this.listItems.find((listItem) => listItem.id === activeDescendantId) || null;
	}

	set activeListItem(listItem: ListItem) {
		const id = listItem && listItem.id || null;
		const activeDescendantId = this.element.getAttribute('aria-activedescendant');

		this.activeListItemIndex = this.listItems.findIndex((li) => li.id === id);

		this.listItems.forEach((li) => {
			li.active = id === li.id;
		});

		if (id) {
			this.element.setAttribute('aria-activedescendant', id);
		} else {
			this.element.removeAttribute('aria-activedescendant');
		}

		if (!listItem) return;

		if (this.variant === VARIANTS.navigable) {
			listItem.element.querySelector('a').focus();
		}

		this._statusRegion.broadcast(
			RegionSettings.format(
				listItem.disabled ? 'list.sr.disabled' : 'list.sr.active',
				listItem.text
			)
		);

		if (this.variant !== VARIANTS.navigable) dom.scrollIntoViewIfNeeded(listItem.element, true);
	}

	get listItems(): ListItem[] {
		return this._listItems;
	}

	get multiselect(): boolean {
		return this.element.getAttribute('aria-multiselectable') === 'true';
	}

	set multiselect(bool: boolean) {
		this.element.setAttribute('aria-multiselectable', bool.toString());
	}

	get readOnly(): boolean {
		const variant = this.variant;

		return variant === VARIANTS.static;
	}

	set selectedListItems(listItems: ListItem[]) {
		const oldSelectedListItems = this.selectedListItems;

		const selectedListItems = this.listItems.filter((listItem) => {
			return listItems.find((selectedListItem) => selectedListItem.id === listItem.id && !listItem.disabled);
		});

		const sameListItems = selectedListItems.filter((selectedListItem) => {
			return oldSelectedListItems.find((oldListItem) => oldListItem.id === selectedListItem.id);
		});

		// if the items has changed we will update them and fire an event.
		if (selectedListItems.length !== oldSelectedListItems.length || selectedListItems.length !== sameListItems.length) {

			this.listItems.forEach((listItem) => {
				listItem.selected = !!selectedListItems.find((selectedListItem) => selectedListItem.id === listItem.id);
			});
			this._dispatchChangeEvent();
		}
	}

	get selectedListItems(): ListItem[] {
		return this.listItems.filter((item) => item.selected);
	}

	get value(): string | string[] {
		const selectedItems = this.selectedListItems || [];
		if (this.multiselect) {
			return selectedItems.map((listItem) => listItem.value);
		}

		return (selectedItems[0] && selectedItems[0].value) || null;
	}

	set value(value: string | string[]) {
		const values = typeof value === 'string' ? [value] : value;
		const selectedListItems = this.listItems.filter((listItem) => values.find((val) => val === listItem.value)) || [];

		this.selectedListItems = selectedListItems;
	}

	get variant(): VariantType {
		const variants = Object.values(VARIANTS) as VariantType[];
		const variant = (utility.splitAttributeToList(this.element.getAttribute('class')).find((className) => {
			return !!variants.find((_variant) => this._options.componentClass + '--' + _variant === className);
		}) || this._options.componentClass + '--' + VARIANTS.static).replace(this._options.componentClass + '--', '') as VariantType;

		return variant;
	}

	set variant(variant: VariantType) {
		this.element.classList.add(this._options.componentClass + '--' + variant);
		this._updateListRole();
	}

	public destroy(): void {
		this._mutationObserver.disconnect();

		if (this.element) {
			this.element.removeEventListener('_listitemselected', this._listItemSelectedHandler);
			this.element.removeEventListener('keydown', this._keypressHandler);
			this.element.removeEventListener('focusin', this._focusinHandler);
		}

		this.listItems.forEach((listItem) => {
			listItem.destroy();
		});
	}

	private get _options(): IListComponentOptions {
		return this.getOptions() as IListComponentOptions;
	}

	private _listItemSelectedHandler = (event: CustomEvent) => {
		this._selectListItem(event.detail.component as ListItem);
	}

	private _keypressHandler = (event: Event) => {
		return createKeypressEventListener({
			up: this._decreaseActiveItemPosition,
			down: this._increaseActiveItemPosition,
			left: this._decreaseActiveItemPosition,
			right: this._increaseActiveItemPosition,
			spacebar: this._selectActiveItem,
			enter: this._selectActiveItem,
			escape: this._escHandler,
			tab: this._tabHandler,
		} as IKeyPressHandlers)(event);
	}

	private _focusinHandler = () => {
		// if there is no active list item, focus the next element; otherwise, return true
		if (this.activeListItem !== null) return true;
		return this._moveActiveItemPosition(0);
	}

	private _tabHandler = (event: KeyboardEvent) => {
		// if first or last item is active and input tab, do not loop; if last item or first item + shift, return true
		if ((event.shiftKey && this.listItems[0].id === this.activeListItem.id) || (this.listItems.slice(-1).pop().id === this.activeListItem.id)) {
			return true;
		}
		// if shift tab, decrease active item position; otherwise, increase active item position
		if (event.shiftKey) {
			return this._decreaseActiveItemPosition();
		} else {
			return this._increaseActiveItemPosition();
		}
		return true;
	}

	private _escHandler = () => {
		this.activeListItem = null;
		return this.variant === VARIANTS.navigable;
	}

	private _dispatchChangeEvent() {
		dom.dispatchEvent(this.element, 'change', {
			component: this,
			value: this.value,
		});
	}

	private _moveActiveItemPosition = (delta: number): boolean => {
		if (delta === 0) {
			this.activeListItem = this.listItems[0];
		} else {
			this.activeListItem = this.listItems[this._determineNextItemIndex(delta)];
		}
		return false;
	}

	private _decreaseActiveItemPosition = (): boolean => {
		return this._moveActiveItemPosition(-1);
	}

	private _increaseActiveItemPosition = (): boolean => {
		return this._moveActiveItemPosition(1);
	}

	private _determineNextItemIndex = (steps: number): number => {
		const activeIndex = this.activeListItemIndex;
		const listItems = this.listItems;
		const minIndex = 0;
		const maxIndex = listItems.length - 1;
		let movedIndex = activeIndex + steps;

		if (steps === 0) {
			return steps;
		}

		if (this.variant === VARIANTS.navigable) {
			if (movedIndex > maxIndex) {
				movedIndex = minIndex;
				return movedIndex;
			} else if (movedIndex < minIndex) {
				movedIndex = maxIndex;
				return movedIndex;
			}
		}

		if (movedIndex < minIndex) {
			movedIndex = 0;
		}

		if (movedIndex > maxIndex) {
			movedIndex = maxIndex;
		}

		return movedIndex;
	}

	private _selectActiveItem = (): boolean => {
		const activeListItem = this.activeListItem;
		// If activeListItem is equivalent to false or has a disabled property, return false
		if (!activeListItem || activeListItem.disabled) {
			return false;
		}

		if (this.variant === VARIANTS.navigable) {
			return true;
		}

		this._selectListItem(activeListItem);
		this._statusRegion.broadcast(RegionSettings.format(activeListItem.selected ? 'list.sr.selected' : 'list.sr.unselected', activeListItem.text));

		return false;
	}

	private _selectListItem(listItem: ListItem) {
		let selectedListItems = this.selectedListItems;

		if (this.multiselect) {
			const listItemIndex = selectedListItems.findIndex((selectedListItem) => selectedListItem.id === listItem.id);
			if (listItemIndex >= 0) {
				selectedListItems = selectedListItems.filter((selectedListItem) => selectedListItem.id !== listItem.id);
			} else {
				selectedListItems.push(listItem);
			}
		} else {
			selectedListItems = [listItem];
		}

		this.selectedListItems = selectedListItems;
	}

	private _updateListRole(): void {
		const previousRole = this.element.getAttribute('role');
		const previousTabindex = this.element.getAttribute('tabindex') || null;

		const role = this.readOnly ? 'list' : 'listbox';
		const tabindex = this.readOnly ? null : '0';

		if (previousRole !== role) {
			this.element.setAttribute('role', role);
		}

		if (previousTabindex !== tabindex) {
			if (tabindex) {
				this.element.setAttribute('tabindex', tabindex);
			} else {
				this.element.removeAttribute('tabindex');
			}
		}
	}

	private _updateListItems(): void {
		this._listItems = utility.toArray(this.element.querySelectorAll('.' + this._options.listItemClass))
			.map((listItemElement: HTMLElement) => {
				const activeListItemId = this.element.getAttribute('aria-activedescendant') || null;
				const listItem = this._listItems.find((oldItem) => oldItem.id === listItemElement.id) || new ListItem(listItemElement);
				listItem.readOnly = this.readOnly;

				if (listItem.active && listItem.id !== activeListItemId) {
					this.activeListItem = listItem;
				}

				return listItem;
			});
	}

	private static _defaultComponentOptions: IListComponentOptions = {
		componentClass: 'iris-list',
		listItemClass: 'iris-list-item',
		idPrefix: 'iris_list',
	};

	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '.' + ListComponent._defaultComponentOptions.componentClass,
		componentName: 'ListComponent'
	};

	public static factory = new GenericComponentFactory<ListComponent, IListComponentOptions>(ListComponent, ListComponent._factoryOptions, ListComponent._defaultComponentOptions);

	public static VARIANTS = VARIANTS;
}
